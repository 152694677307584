var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.view.wideSubTables, function(table) {
      return _c(
        "div",
        { staticClass: "infl-prdgm-tbl" },
        _vm._l(table.rows, function(row) {
          return _c(
            "div",
            { staticClass: "infl-prdgm-tbl__row" },
            _vm._l(row.cells, function(cell) {
              return _c(
                "div",
                {
                  staticClass: "infl-prdgm-tbl__cell",
                  class: _vm.cellClasses(cell)
                },
                [
                  _vm._v("\n        " + _vm._s(cell.value) + "\n        "),
                  !!cell.reflink
                    ? _c(
                        "a",
                        {
                          staticClass: "infl-prdgm-tbl__cell-reflink",
                          style: {
                            backgroundColor: _vm.refColor(cell.reflink.id)
                          },
                          on: {
                            click: function($event) {
                              return _vm.navigate(cell.reflink.id)
                            }
                          }
                        },
                        [_vm._v(_vm._s(cell.reflink.text))]
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }