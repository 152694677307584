var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "infl-suff-footnote-link",
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.showPopup($event)
        }
      }
    },
    [
      _vm._l(_vm.footnotes, function(footnote, index) {
        return _c(
          "sup",
          [
            _vm._v("\n    " + _vm._s(footnote.index) + "\n    "),
            index < _vm.footnotes.length - 1 ? [_vm._v(",")] : _vm._e()
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.footnotesPopupVisible,
              expression: "footnotesPopupVisible"
            }
          ],
          staticClass: "alpheios-inflections__footnote-popup",
          style: [_vm.popupAlignmentStyles]
        },
        [
          _c(
            "div",
            { staticClass: "alpheios-inflections__footnote-popup-title" },
            [_vm._v("Footnotes:")]
          ),
          _vm._v(" "),
          _vm._l(_vm.footnotes, function(footnote) {
            return [
              _c("dt", [
                _vm._v("\n        " + _vm._s(footnote.index) + "\n      ")
              ]),
              _vm._v(" "),
              _c("dd", [
                _vm._v("\n        " + _vm._s(footnote.text) + "\n      ")
              ])
            ]
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "alpheios-inflections__footnote-popup-close-btn",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.hidePopup($event)
                }
              }
            },
            [
              _c("svg", { attrs: { viewBox: "0 0 20 20" } }, [
                _c("path", { attrs: { d: "M16 16L4 4M16 4L4 16" } })
              ])
            ]
          )
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }