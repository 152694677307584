var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$store.state.toolbar.visible,
          expression: "$store.state.toolbar.visible"
        }
      ],
      staticClass:
        "alpheios-content alpheios-toolbar alpheios-toolbar--compact",
      style: _vm.componentStyles,
      attrs: { id: "alpheios-toolbar-inner" },
      on: { click: _vm.ui.toggleActionPanel }
    },
    [_c("toolbar-icon")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }