//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'Info',
  inject: ['app', 'l10n'],
  storeModules: ['app'],
  mixins: [DependencyCheck]
}
