var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c("div", { attrs: { id: "" + _vm.data.paradigmID } }, [
        _c("h3", { staticClass: "alpheios-inflections__supp-table-title" }, [
          _vm._v(_vm._s(_vm.data.title))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "infl-supp-tbl__cont" }, [
          _c(
            "div",
            {
              staticClass: "infl-prdgm-tbl infl-prdgm-tbl--supp",
              style: { backgroundColor: _vm.bgColor }
            },
            _vm._l(_vm.data.table.rows, function(row) {
              return _c(
                "div",
                { staticClass: "infl-prdgm-tbl__row" },
                _vm._l(row.cells, function(cell) {
                  return _c(
                    "div",
                    {
                      staticClass: "infl-prdgm-tbl__cell",
                      class: _vm.cellClasses(cell)
                    },
                    [_vm._v("\n          " + _vm._s(cell.value) + "\n        ")]
                  )
                }),
                0
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "infl-supp-tbl__reflink",
              on: { click: _vm.navigate }
            },
            [
              _vm._v(
                _vm._s(_vm.l10n.getMsg("INFLECTIONS_MAIN_TABLE_LINK_TEXT"))
              )
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }