//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WordUsageExamplesFilters from '@/vue/components/word-usage-examples/word-usage-examples-filters.vue'
import WordUsageExamplesSorting from '@/vue/components/word-usage-examples/word-usage-examples-sorting.vue'

import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'WordUsageExamples',
  inject: ['ui', 'app', 'l10n'],
  storeModules: ['ui'],
  mixins: [DependencyCheck],
  components: {
    wordUsageExamplesFilters: WordUsageExamplesFilters,
    wordUsageExamplesSorting: WordUsageExamplesSorting
  },
  data () {
    return {
      sortBy: null,
      selectedAuthor: null,
      selectedTextWork: null,
      needInnerFilter: false,
      // Whether to show reference links on mobile layout or not
      showDataSource: false,
      collapsedHeader: true,
      reloadSorting: 0,
      hasSelectedAuthor: false,
      hasSelectedTextWork: false
    }
  },
  computed: {
    targetWord () {
      return this.$store.state.app.homonymDataReady && this.app.homonym ? this.app.homonym.targetWord : null
    },
    language () {
      return this.$store.state.app.homonymDataReady && this.app.homonym ? this.app.homonym.language : null
    },
    showHeaderFilters () {
      return this.$store.state.app.wordUsageExamplesReady
    },
    showHeader () {
      return Boolean(this.selectedAuthor) ||
             this.showWordUsageExampleItems
    },
    showWordUsageExampleItems () {
      if (!this.$store.state.app.wordUsageExamplesReady) {
        this.collapsedHeader = true
      }
      return this.$store.state.app.wordUsageExamplesReady
    },
    wordUsageExamples () {
      if (!this.$store.state.app.wordUsageExamplesReady) {
        return []
      }
      if (this.selectedAuthor && this.needInnerFilter) {
        return this.app.wordUsageExamples.wordUsageExamples
          .filter(wordUsageExample => {
            return wordUsageExample.author && (wordUsageExample.author.ID === this.selectedAuthor.ID) && (this.selectedTextWork ? wordUsageExample.textWork.ID === this.selectedTextWork.ID : true)
          })
      }
      return this.app.wordUsageExamples.wordUsageExamples
    },
    provider () {
      return this.$store.state.app.wordUsageExamplesReady && this.app.wordUsageExamples.provider ? this.app.wordUsageExamples.provider.toString() : null
    },
    providerRights () {
      return (this.app.wordUsageExamples && this.app.wordUsageExamples.provider && this.app.wordUsageExamples.provider.rights)
        ? Array.from(this.app.wordUsageExamples.provider.rights.entries()).map(([key, value]) => { return { key, value } })
        : []
    },
    wordUsageListSorted () {
      // TODO support user-selected sort key and order
      // eventually sorting should also take language into account but
      // for now we will probably only show Latin author and work names anyway
      if (this.showWordUsageExampleItems && this.wordUsageExamples) {
        return this.sortWordUsageExamplesBy()
      }
      return []
    },
    collapsedHeaderTitle () {
      return this.collapsedHeader ? this.l10n.getText('WORDUSAGE_SHOW_FILTERS_TEXT') : this.l10n.getText('WORDUSAGE_HIDE_FILTERS_TEXT')
    }
  },
  methods: {
    changedSortBy (sortByFromHeader) {
      this.sortBy = sortByFromHeader
    },
    setAuthorTextWork (selectedAuthor, selectedTextWork) {
      this.selectedAuthor = selectedAuthor
      this.selectedTextWork = selectedTextWork

      this.hasSelectedAuthor = Boolean(selectedAuthor)
      this.hasSelectedTextWork = Boolean(selectedTextWork)
    },
    filterCurrentByAuthor (selectedAuthor, selectedTextWork) {
      this.setAuthorTextWork(selectedAuthor, selectedTextWork)
      this.needInnerFilter = true
      this.collapsedHeader = true
    },
    getMoreResults (selectedAuthor, selectedTextWork) {
      this.setAuthorTextWork(selectedAuthor, selectedTextWork)
      this.needInnerFilter = false
      this.reloadSorting = this.reloadSorting + 1
    },
    getAllResults () {
      this.setAuthorTextWork(null, null)
      this.needInnerFilter = false
      this.collapsedHeader = true
      this.reloadSorting = this.reloadSorting + 1
    },
    getPropertyBySortBy (a, type) {
      switch (type) {
        case 'byAuthor':
          return a.authorForSort()
        case 'byTextWork':
          return a.textWorkForSort()
        case 'byPrefix':
          return a.prefixForSort
        case 'bySuffix':
          return a.suffixForSort
        default:
          return a.fullCit().toUpperCase()
      }
    },
    sortWordUsageExamplesBy () {
      return this.wordUsageExamples.sort((a, b) => {
        let aU = this.getPropertyBySortBy(a, this.sortBy)
        let bU = this.getPropertyBySortBy(b, this.sortBy)
        if (aU < bU) {
          return -1
        }
        if (aU > bU) {
          return 1
        }
        return 0
      })
    },
    changeShowDataSource () {
      this.showDataSource = !this.showDataSource
    },
    formattedFullCit (wordUsageItem) {
      return wordUsageItem.formattedAuthor + ' <i>' + wordUsageItem.formattedTextWork + '</i> ' + wordUsageItem.formattedPassage
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.ui.registerAndActivateGetSelectedText('getSelectedText-usageExamples', '.alpheios-word-usage')
    })
  }
}
