var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alpheios-ui-options__cont" },
    [
      _c("font-size"),
      _vm._v(" "),
      _c("setting", {
        attrs: {
          classes: ["alpheios-ui-options__item"],
          data: _vm.settings.uiOptions.items.panelPosition
        },
        on: { change: _vm.uiOptionChanged }
      }),
      _vm._v(" "),
      _c("setting", {
        attrs: {
          classes: ["alpheios-ui-options__item"],
          data: _vm.settings.uiOptions.items.popupPosition
        },
        on: { change: _vm.uiOptionChanged }
      }),
      _vm._v(" "),
      _c("setting", {
        attrs: {
          classes: ["alpheios-ui-options__item"],
          data: _vm.settings.uiOptions.items.verboseMode
        },
        on: { change: _vm.uiOptionChanged }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }