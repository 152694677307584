//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
    This is a desktop version of a panel
     */
// JS imports
import interact from 'interactjs'
// UI components
import NavbuttonsLarge from '@/vue/components/nav/navbuttons-large.vue'
// SVG icons
import LogoIcon from '@/images/alpheios/logo.svg'
import SwapPosition from '@/images/inline-icons/swap-horizontally.svg'
// Vue components
import CompactPanel from '@/vue/components/panel-compact.vue'
import Tooltip from '@/vue/components/tooltip.vue'
import Info from '@/vue/components/info.vue'

export default {
  name: 'PanelLarge',
  extends: CompactPanel,
  components: {
    alphTooltip: Tooltip,
    info: Info,
    navbuttonsLarge: NavbuttonsLarge,
    logoIcon: LogoIcon,
    swapPosition: SwapPosition
  },
  // A minimal width of a panel, in pixels. It should be large enough to fit all the buttons of a large size into the panel
  minWidth: 698,
  // Maximum allowed size of a panel, as percentage of the viewport width.
  maxWidthPct: 80,

  computed: {
    rootClasses () {
      return this.$options.positionClassVariants[this.$store.state.panel.position]
    },

    swapTooltip () {
      return this.isAttachedToLeft
        ? this.l10n.getText('TOOLTIP_MOVE_PANEL_RIGHT')
        : this.l10n.getText('TOOLTIP_MOVE_PANEL_LEFT')
    }
  },

  mounted: function () {
    // Determine paddings and sidebar width for calculation of a panel width to fit content
    if (typeof this.$el.querySelector === 'function') {
      const maxWidth = Math.floor(document.documentElement.clientWidth / 100 * this.$options.maxWidthPct)

      // Initialize Interact.js: make panel resizable
      interact(this.$el)
        .resizable({
          // resize from all edges and corners
          edges: { left: true, right: true, bottom: false, top: false },

          // keep the edges inside the parent
          restrictEdges: {
            outer: document.body,
            endOnly: true
          },

          // minimum size
          restrictSize: {
            min: { width: this.$options.minWidth },
            max: { width: maxWidth }
          },

          inertia: true
        })
        .on('resizemove', event => {
          let target = event.target
          // Indicate that panel received a custom size
          this.resized = true
          // update the element's style
          target.style.width = `${event.rect.width}px`
        })
    }
  }
}
