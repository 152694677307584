var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alpheios-lookup__form" },
    [
      _c(
        "div",
        { staticClass: "alpheios-lookup__form-row" },
        [
          _vm.showLanguageSettingsGroup
            ? _c("alph-setting", {
                attrs: {
                  classes: [
                    "alpheios-panel__options-item",
                    "alpheios-lookup__form-element",
                    "alpheios-lookup__lang-control"
                  ],
                  data: this.$options.lookupLanguage
                },
                on: { change: _vm.settingChange }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-lookup__form-element" }, [
            _c("label", { staticClass: "alpheios-setting__label" }, [
              _vm._v("Word lookup")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "alpheios-lookup__search-control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.lookuptext,
                    expression: "lookuptext"
                  }
                ],
                staticClass: "alpheios-input",
                attrs: {
                  placeholder: _vm.l10n.getMsg("LABEL_LOOKUP_BUTTON"),
                  autocapitalize: "off",
                  autocorrect: "off",
                  type: "text"
                },
                domProps: { value: _vm.lookuptext },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.lookup($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.lookuptext = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "alpheios-button-primary",
                  attrs: { tabindex: "-1", type: "button" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.lookup($event)
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "alpheios-lookup__search-control-label" },
                    [_vm._v(_vm._s(_vm.l10n.getMsg("LABEL_LOOKUP_BUTTON")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "alpheios-lookup__search-control-icon alpheios-navbuttons__btn"
                    },
                    [_c("lookup-icon")],
                    1
                  )
                ]
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.showLanguageSettingsGroup
        ? [
            _c(
              "div",
              { staticClass: "alpheios-lookup__settings" },
              _vm._l(_vm.lexiconsFiltered, function(lexicon) {
                return _c("alph-setting", {
                  key: lexicon.name,
                  attrs: {
                    classes: [
                      "alpheios-panel__options-item",
                      "alpheios-lookup__resource-control"
                    ],
                    data: lexicon
                  },
                  on: { change: _vm.resourceSettingChange }
                })
              }),
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }