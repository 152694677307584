var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showPanel,
          expression: "showPanel"
        }
      ],
      staticClass: "alpheios-action-panel alpheios-content",
      style: _vm.componentStyles,
      attrs: { id: _vm.config.rootElementId }
    },
    [
      _c("close-icon", {
        staticClass: "alpheios-action-panel__close-icon",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.$store.commit("actionPanel/close")
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "alpheios-action-panel__lookup-cont" },
        [
          _c("lookup", {
            staticClass: "alpheios-action-panel__lookup",
            attrs: {
              "name-base": "action-panel",
              "use-page-lang-prefs": true,
              "show-language-settings-group": false,
              "show-results-in": _vm.config.lookupResultsIn
            },
            on: { "lookup-started": _vm.lookupStarted }
          }),
          _vm._v(" "),
          _vm.$store.getters["app/lexicalRequestInProgress"]
            ? _c("progress-bar", {
                staticClass: "alpheios-action-panel__progress-bar"
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "alpheios-action-panel__nav-cont" },
        [
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.tooltipText("TOOLTIP_INFLECT_BROWSER"),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "alpheios-action-panel__navbutton",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.openTab("inflectionsbrowser")
                    }
                  }
                },
                [_c("inflections-browser-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.tooltipText(
                  "TOOLTIP_GRAMMAR",
                  _vm.$store.getters["app/hasGrammarRes"]
                ),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "alpheios-action-panel__navbutton",
                  class: { disabled: !_vm.$store.getters["app/hasGrammarRes"] },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.$store.getters["app/hasGrammarRes"]
                        ? _vm.openTab("grammar")
                        : null
                    }
                  }
                },
                [_c("grammar-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.tooltipText(
                  "TOOLTIP_WORDLIST",
                  _vm.$store.state.app.hasWordListsData
                ),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "alpheios-action-panel__navbutton",
                  class: { disabled: !_vm.$store.state.app.hasWordListsData },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.$store.state.app.hasWordListsData
                        ? _vm.openTab("wordlist")
                        : null
                    }
                  }
                },
                [_c("wordlist-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.tooltipText(
                  "TOOLTIP_USER",
                  _vm.$store.state.auth.enableLogin
                ),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "alpheios-action-panel__navbutton",
                  class: { disabled: !_vm.$store.state.auth.enableLogin },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.$store.state.auth.enableLogin
                        ? _vm.openTab("user")
                        : null
                    }
                  }
                },
                [_c("user-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.tooltipText("TOOLTIP_OPTIONS"),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "alpheios-action-panel__navbutton",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.openTab("options")
                    }
                  }
                },
                [_c("options-icon")],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }