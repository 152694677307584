var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showHeader && _vm.availableSortBy && !_vm.collapsedHeader,
          expression: "showHeader && availableSortBy && !collapsedHeader"
        }
      ],
      staticClass: "alpheios-word-usage-header-sorting"
    },
    [
      _c("p", { staticClass: "alpheios-word-usage-header-title" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.l10n.getText("WORDUSAGE_SORT_BY")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "alpheios-word-usage-sorting-select" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedSortBy,
                expression: "selectedSortBy"
              }
            ],
            staticClass:
              "alpheios-select alpheios-word-usage-header-select-sortBy",
            on: {
              change: [
                function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedSortBy = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.changedSortBy
              ]
            }
          },
          _vm._l(_vm.finalTypeSortingList, function(typeSorting) {
            return _c(
              "option",
              {
                key: typeSorting.value,
                domProps: { value: typeSorting.value }
              },
              [_vm._v(_vm._s(typeSorting.title))]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }