//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'UserAuth',
  inject: {
    l10n: 'l10n',
    auth: { from: 'auth', default: null } // This module is options
  },
  props: {
    btnClass: {
      type: String,
      default: 'alpheios-button-primary',
      required: false
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    loginLink: function () {
      if (this.$store.state.auth.externalLoginUrl) {
        return this.$store.state.auth.externalLoginUrl.replace('{FROM_URL}',window.location.href)
      }
    },
    logoutLink: function () {
      if (this.$store.state.auth.externalLoginUrl) {
        return this.$store.state.auth.externalLogoutUrl.replace('{FROM_URL}',window.location.href)
      }
    }
  },
  methods: {
    logIn: function () {
      this.auth.authenticate()
    },

    logOut: function () {
      this.auth.logout()
    }
  }
}
