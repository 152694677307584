var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "alpheios-wordlist-language__worditem",
      class: _vm.itemClasses
    },
    [
      _c(
        "alph-tooltip",
        {
          attrs: {
            tooltipDirection: "top-left",
            tooltipText: _vm.l10n.getMsg("WORDLIST_TOOLTIP_CHANGE_IMPORTANT")
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-worditem__data alpheios-worditem__icon",
              on: {
                click: function($event) {
                  return _vm.changeImportant()
                }
              }
            },
            [_c("check-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          attrs: {
            tooltipDirection: "top-left",
            tooltipText: _vm.l10n.getMsg("WORDLIST_TOOLTIP_REMOVE")
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "alpheios-worditem__data alpheios-worditem__icon alpheios-worditem__delete_icon",
              on: {
                click: function($event) {
                  return _vm.deleteItem()
                }
              }
            },
            [_c("delete-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          class: {
            "alpheios-visibility__hidden ": !this.worditem.currentSession
          },
          attrs: {
            tooltipDirection: "top-left",
            tooltipText: _vm.l10n.getMsg("WORDLIST_CURRENT_SESSION")
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "alpheios-worditem__data alpheios-worditem__icon alpheios-worditem__current_session_icon"
            },
            [_c("current-session-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          class: {
            "alpheios-visibility__hidden ": !_vm.worditem.hasTextQuoteSelectors
          },
          attrs: {
            tooltipDirection: "top-left",
            tooltipText: _vm.l10n.getMsg("WORDLIST_TOOLTIP_TEXT_CONTEXT")
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "alpheios-worditem__data alpheios-worditem__icon alpheios-worditem__delete_icon",
              on: {
                click: function($event) {
                  return _vm.showContexts()
                }
              }
            },
            [_c("text-quote-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "alpheios-worditem__data alpheios-worditem__targetWord",
          on: {
            click: function($event) {
              return _vm.selectWordItem()
            }
          }
        },
        [_vm._v(_vm._s(_vm.worditem.targetWord))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "alpheios-worditem__data alpheios-worditem__lemmasList"
        },
        _vm._l(_vm.lemmasList, function(lemma, lemmaIndex) {
          return _c(
            "span",
            {
              staticClass: "alpheios-worditem__lemmasList-lemmaitem",
              on: {
                click: function($event) {
                  return _vm.setLemmaFilterByClick(lemma)
                }
              }
            },
            [
              _vm._v(_vm._s(lemma)),
              lemmaIndex < _vm.lemmasList.length - 1
                ? _c("span", [_vm._v(", ")])
                : _vm._e()
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }