//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'ReskinFontColor',
  inject: ['ui', 'l10n'],
  data () {
    return {
      activeButton: 16
    }
  },
  methods: {
    changeFontSize (size) {
      this.ui.optionChange('fontSize', size)
      this.activeButton = size
    }
  }
}
