//
//
//
//
//
//
//
//

import CloseIcon from '@/images/inline-icons/x-close.svg'

export default {
  name: 'EmbedLibWarning',
  components: {
    closeIcon: CloseIcon
  },
  props: ['text'],
  data: function () {
    return {
      visible: true
    }
  },
  methods: {
    close: function () {
      this.visible = false
    }
  }
}
