//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Embeddable SVG icons
import CloseIcon from '@/images/inline-icons/x-close.svg'
// UI modules
import Setting from '@/vue/components/setting.vue'
import Login from '@/vue/components/login.vue'
// Modules support
import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'NotificationArea',
  inject: ['app', 'l10n', 'settings'],
  storeModules: ['ui', 'auth'],
  mixins: [DependencyCheck],
  components: {
    closeIcon: CloseIcon,
    setting: Setting,
    login: Login
  },

  computed: {
    notificationClasses: function () {
      let classes = []
      if (this.$store.state.ui.notification.important) {
        classes.push('alpheios-notification-area__notification--important')
      }
      if (!this.showNotification) {
        // The presence of this class will let the login notification set the top margin
        classes.push(`alpheios-notification-area__notification--hidden`)
      }
      return classes
    },

    showNotification () {
      return this.$store.state.ui.notification.visible && this.$store.state.ui.notification.important
    },

    showLoginNotification () {
      return Boolean(
        this.$store.state.auth.notification.visible &&
        (this.$store.state.auth.notification.count === 1 || this.$store.state.auth.notification.count % 10 === 0)
      )
    }
  },

  methods: {
    featureOptionChanged: function (name, value) {
      this.app.featureOptionChange(name, value)
    }
  }
}
