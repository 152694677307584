//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Subcomponents
import WidePrerenderedTable from './inflections-table-prerendered.vue'
import WideTableVue from './inflections-table-wide.vue'
import WideSubTables from './inflections-subtables-wide.vue'
import WideSuppTable from './inflections-supp-table-wide.vue'
import WordForms from './wordforms.vue'

// Modules support
import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'Inflections',
  inject: ['app', 'l10n'],
  storeModules: ['app', 'ui'], // Store modules that are required by this component
  mixins: [DependencyCheck],
  components: {
    prerenderedTableWide: WidePrerenderedTable,
    mainTableWideVue: WideTableVue,
    subTablesWide: WideSubTables,
    suppTablesWide: WideSuppTable,
    wordForms: WordForms
  },
  visibilityUnwatch: null, // Will hold a function for removal of visibility watcher
  hasInflDataUnwatch: null,

  data: function () {
    return {
      languageID: undefined,
      hasInflectionData: false,
      partsOfSpeech: [],
      selectedPartOfSpeech: [],
      views: [],
      selectedViewName: '',
      selectedView: {},
      renderedView: {},
      elementIDs: {
        panelInner: 'alpheios-panel-inner',
        footnotes: 'alph-inflection-footnotes'
      },
      canCollapse: false // Whether a selected view can be expanded or collapsed (it can't if has no suffix matches)
    }
  },

  computed: {
    partOfSpeechSelector: {
      get: function () {
        return this.selectedPartOfSpeech
      },
      set: function (newValue) {
        this.selectedPartOfSpeech = newValue
        this.views = this.app.getInflectionViews(this.selectedPartOfSpeech)
        if (this.views.length > 0) {
          this.selectedView = this.views[0].render()
        }
      }
    },
    viewSelector: {
      get: function () {
        return this.selectedView ? this.selectedView.id : ''
      },
      set: function (newValue) {
        this.selectedView = this.views.find(view => view.id === newValue).render()
      }
    },
    inflectionTable: function () {
      return this.selectedView.id
    },
    footnotes: function () {
      let footnotes = []
      if (this.selectedView && this.selectedView.footnotes) {
        footnotes = Array.from(this.selectedView.footnotes.values())
      }
      return footnotes
    },
    forms: function () {
      let forms = []
      if (this.selectedView && this.selectedView.forms) {
        forms = Array.from(this.selectedView.forms.values())
      }
      return forms
    },
    showExplanatoryHint: function () {
      return this.selectedView && this.selectedView.constructor && this.selectedView.constructor.name === 'GreekParadigmView'
    }
  },

  methods: {
    initViewSet () {
      this.hasInflectionData = false
      if (this.$store.state.app.hasInflData) {
        const inflectionsViewSet = this.app.getInflectionsViewSet()
        this.languageID = inflectionsViewSet.languageID
        if (inflectionsViewSet.hasMatchingViews) {
          this.partsOfSpeech = inflectionsViewSet.partsOfSpeech
          if (this.partsOfSpeech.length > 0) {
            this.selectedPartOfSpeech = this.partsOfSpeech[0]
            this.views = inflectionsViewSet.getViews(this.selectedPartOfSpeech)
          } else {
            this.selectedPartOfSpeech = []
            this.views = []
          }

          if (this.views.length > 0) {
            this.hasInflectionData = true
            this.selectedView = this.views[0].render()
          } else {
            this.selectedView = ''
          }
        }
      }
    },

    navigate (reflink) {
      let panel = document.querySelector(`#${this.elementIDs.panelInner}`)
      if (!panel) {
        console.warn(`Cannot find panel's inner element #${this.elementIDs.panelInner}. Scroll is cancelled`)
      }
      if (reflink === 'top') {
        // Navigate to the top of the page
        panel.scrollTop = 0
      } else {
        // Navigate to one of the supplemental tables
        const paddingTop = 20 // A margin between an element and a top of a visible area, in pixels
        let el = document.querySelector(`#${reflink}`)
        if (el) {
          const offset = Math.round(el.offsetTop)
          panel.scrollTop = offset - paddingTop
        } else {
          console.warn(`Cannot find #${reflink} element. Navigation is cancelled`)
        }
      }
    }
  },

  mounted: function () {
    this.initViewSet()

    this.$options.visibilityUnwatch = this.$store.watch((state) => state.ui.activeTab, (tabName) => {
      if (tabName === 'inflections') {
        // Scroll to top if panel is reopened
        this.navigate('top')
      }
    })

    this.$options.hasInflDataUnwatch = this.$store.watch((state) => state.app.hasInflData, (value) => {
      if (value) {
        // Inflections data has become available
        this.initViewSet()
      }
    })
  },

  beforeDestroy: function () {
    this.$options.visibilityUnwatch()
    this.$options.hasInflDataUnwatch()
  }
}
