//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Morph from './morph.vue'
import NotificationArea from './notification-area.vue'
import interact from 'interactjs'
import Logger from '@/lib/log/logger'

import Tooltip from './tooltip.vue'
import ProgressBar from './progress-bar.vue'
// Embeddable SVG icons
import LogoIcon from '@/images/alpheios/logo.svg'
import CloseIcon from '@/images/inline-icons/x-close.svg'
import DefinitionsIcon from '@/images/inline-icons/definitions.svg'
import WordUsageIcon from '@/images/inline-icons/usage-examples-icon1.svg'
import InflectionsIcon from '@/images/inline-icons/inflections.svg'
import TreebankIcon from '@/images/inline-icons/sitemap.svg'

import { directive as onClickaway } from '../directives/clickaway.js'
// Modules support
import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'Popup',
  inject: ['app', 'ui', 'l10n', 'settings', 'auth'],
  storeModules: ['app', 'ui', 'popup', 'auth'],
  mixins: [DependencyCheck],
  components: {
    morph: Morph,
    logoIcon: LogoIcon,
    closeIcon: CloseIcon,
    alphTooltip: Tooltip,
    progressBar: ProgressBar,
    notificationArea: NotificationArea,
    definitionsIcon: DefinitionsIcon,
    wordUsageIcon: WordUsageIcon,
    inflectionsIcon: InflectionsIcon,
    treebankIcon: TreebankIcon
  },
  directives: {
    onClickaway: onClickaway
  },
  // Custom props to store unwatch functions
  visibleUnwatch: null,
  lexrqStartedUnwatch: null,
  positioningUnwatch: null,

  data: function () {
    return {
      resizable: true,
      draggable: true,
      // Whether there is an error with Interact.js drag coordinates in the corresponding direction
      dragErrorX: false,
      dragErrorY: false,
      interactInstance: undefined,
      lexicalDataContainerID: 'alpheios-lexical-data-container',
      morphComponentID: 'alpheios-morph-component',

      // Current positions and sizes of a popup
      positionTopValue: 0,
      positionLeftValue: 0,
      widthValue: 0,
      heightValue: 0,
      exactWidth: 0,
      exactHeight: 0,
      resizeDelta: 20, // Changes in size below this value (in pixels) will be ignored to avoid minor dimension updates
      resizeCount: 0, // Should not exceed `resizeCountMax`
      resizeCountMax: 100, // Max number of resize iteration
      // If resized manually, the following two props will contain adjusted with and height
      resizedWidth: null,
      resizedHeight: null,

      // How much a popup has been dragged from its initial position, in pixels
      shift: {
        x: 0,
        y: 0
      },

      updateDimensionsTimeout: null,

      showProviders: false
    }
  },

  created () {
    // This is the earliest moment when data props are available
    this.shift.x = this.moduleConfig.initialShift.x
    this.shift.y = this.moduleConfig.initialShift.y

    let vm = this
    this.$on('updatePopupDimensions', function () {
      vm.updatePopupDimensions()
    })
  },
  computed: {
    componentStyles: function () {
      return {
        left: this.positionLeftDm,
        top: this.positionTopDm,
        width: this.widthDm,
        height: this.heightDm,
        zIndex: this.ui.zIndex,
        transform: `translate(${this.shift.x}px, ${this.shift.y}px)`
      }
    },

    logger: function () {
      return Logger.getLogger(this.verboseMode)
    },
    noLanguage: function () {
      return Boolean(!this.$store.state.app.currentLanguageName)
    },

    positionLeftDm: function () {
      if (!this.$store.state.popup.visible) {
        // Reset if popup is invisible
        return '0px'
      }

      if (this.$store.getters['popup/isFixedPositioned']) {
        return this.moduleConfig.initialPos.left
      }

      let left = this.positionLeftValue
      let placementTargetX = this.$store.state.app.selectionTarget.x
      let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      let verticalScrollbarWidth = window.innerWidth - document.documentElement.clientWidth
      let leftSide = placementTargetX - this.exactWidth / 2
      let rightSide = placementTargetX + this.exactWidth / 2
      if (this.widthDm !== 'auto') {
        // Popup is too wide and was restricted in height
        this.logger.log(`Setting position left for a set width`)
        left = this.moduleConfig.viewportMargin
      } else if (rightSide < viewportWidth - verticalScrollbarWidth - this.moduleConfig.viewportMargin &&
          leftSide > this.moduleConfig.viewportMargin) {
        // We can center it with the target
        left = placementTargetX - Math.floor(this.exactWidth / 2)
      } else if (leftSide > this.moduleConfig.viewportMargin) {
        // There is space at the left, move it there
        left = viewportWidth - verticalScrollbarWidth - this.moduleConfig.viewportMargin - this.exactWidth
      } else if (rightSide < viewportWidth - verticalScrollbarWidth - this.moduleConfig.viewportMargin) {
        // There is space at the right, move it there
        left = this.moduleConfig.viewportMargin
      }
      return `${left}px`
    },

    positionTopDm: function () {
      if (!this.$store.state.popup.visible) {
        // Reset if popup is invisible
        return '0px'
      }

      if (this.$store.getters['popup/isFixedPositioned']) {
        return this.moduleConfig.initialPos.top
      }

      let time = Date.now()
      this.logger.log(`${time}: position top calculation, offsetHeight is ${this.exactHeight}`)
      let top = this.positionTopValue
      let placementTargetY = this.$store.state.app.selectionTarget.y
      let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      let horizontalScrollbarWidth = window.innerHeight - document.documentElement.clientHeight
      if (this.heightDm !== 'auto') {
        // Popup is too wide and was restricted in height
        this.logger.log(`Setting position top for a set height`)
        top = this.moduleConfig.viewportMargin
      } else if (placementTargetY + this.moduleConfig.placementMargin + this.exactHeight < viewportHeight - this.moduleConfig.viewportMargin - horizontalScrollbarWidth) {
        // Place it below a selection
        top = placementTargetY + this.moduleConfig.placementMargin
      } else if (placementTargetY - this.moduleConfig.placementMargin - this.exactHeight > this.moduleConfig.viewportMargin) {
        // Place it above a selection
        top = placementTargetY - this.moduleConfig.placementMargin - this.exactHeight
      } else if (placementTargetY < viewportHeight - horizontalScrollbarWidth - placementTargetY) {
        // There is no space neither above nor below. Word is shifted to the top. Place a popup at the bottom.
        top = viewportHeight - horizontalScrollbarWidth - this.moduleConfig.viewportMargin - this.exactHeight
      } else if (placementTargetY > viewportHeight - horizontalScrollbarWidth - placementTargetY) {
        // There is no space neither above nor below. Word is shifted to the bottom. Place a popup at the top.
        top = this.moduleConfig.viewportMargin
      } else {
        // There is no space neither above nor below. Center it vertically.
        top = Math.round((viewportHeight - horizontalScrollbarWidth - this.exactHeight) / 2)
      }
      time = Date.now()
      this.logger.log(`${time}: position top getter, return value is ${top}, offsetHeight is ${this.exactHeight}`)
      return `${top}px`
    },

    widthDm: {
      get: function () {
        if (this.resizedWidth !== null) {
          // Popup has been resized manually
          return `${this.resizedWidth}px`
        }
        return this.widthValue === 'auto' ? 'auto' : `${this.widthValue}px`
      },
      set: function (newWidth) {
        let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        let verticalScrollbarWidth = window.innerWidth - document.documentElement.clientWidth
        let maxWidth = viewportWidth - 2 * this.moduleConfig.viewportMargin - verticalScrollbarWidth
        if (newWidth >= maxWidth) {
          this.logger.log(`Popup is too wide, limiting its width to ${maxWidth}px`)
          this.widthValue = maxWidth
          this.exactWidth = this.widthValue
        } else {
          this.widthValue = 'auto'
        }
      }
    },

    heightDm: {
      get: function () {
        let time = Date.now()
        if (this.resizedHeight !== null) {
          // Popup has been resized manually
          return `${this.resizedHeight}px`
        }
        this.logger.log(`${time}: height getter, return value is ${this.heightValue}`)
        return this.heightValue === 'auto' ? 'auto' : `${this.heightValue}px`
      },
      set: function (newHeight) {
        let time = Date.now()
        this.logger.log(`${time}: height setter, offsetHeight is ${newHeight}`)
        /*
              let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
              let horizontalScrollbarWidth = window.innerHeight - document.documentElement.clientHeight
              let maxHeight = viewportHeight - 2*this.moduleConfig.viewportMargin - horizontalScrollbarWidth
              */
        if (newHeight >= this.maxHeight) {
          this.logger.log(`Popup is too tall, limiting its height to ${this.maxHeight}px`)
          this.heightValue = this.maxHeight
          this.exactHeight = this.heightValue
        } else {
          this.heightValue = 'auto'
        }
      }
    },

    maxHeight () {
      let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      let horizontalScrollbarWidth = window.innerHeight - document.documentElement.clientHeight
      return viewportHeight - 2 * this.moduleConfig.viewportMargin - horizontalScrollbarWidth
    },

    verboseMode () {
      return this.settings.uiOptions.items.verboseMode.currentValue === `verbose`
    }
  },

  methods: {
    switchProviders: function () {
      this.showProviders = !this.showProviders
      if (this.showProviders) {
        // Show credits info
        this.$nextTick(() => {
          let container = this.$el.querySelector(`#${this.lexicalDataContainerID}`)
          if (container) {
            container.scrollTop = container.scrollHeight // Will make it scroll all the way to the bottom
          }
        })
      }
    },

    // Interact.js resizable settings
    resizableSettings: function () {
      return {
        preserveAspectRatio: false,
        edges: { left: true, right: true, bottom: true, top: true },
        restrictEdges: {
          restriction: document.body,
          endOnly: true
        }
      }
    },

    // Interact.js draggable settings
    draggableSettings: function () {
      return {
        inertia: true,
        autoScroll: false,
        restrict: {
          elementRect: { top: 0.5, left: 0.5, bottom: 0.5, right: 0.5 }
        },
        ignoreFrom: 'input, textarea, a[href], select, option'
      }
    },

    resizeListener (event) {
      if (this.resizable) {
        // update dimensions of the element
        this.resizedWidth = event.rect.width
        this.resizedHeight = event.rect.height

        // Update popup position when resizing from top or left edges
        this.shift.x += (event.deltaRect.left || 0)
        this.shift.y += (event.deltaRect.top || 0)
      }
    },

    dragMoveListener (event) {
      if (this.draggable) {
        let dx = event.dx
        let dy = event.dy

        /*
              On some websites Interact.js is unable to determine correct clientX or clientY coordinates.
              This will result in a popup moving abruptly beyond screen limits.
              To fix this, we will filter out erroneous coordinates and chancel a move in the corresponding
              direction as incorrect. This will allow us to keep the popup on screen by sacrificing its movement
              in (usually) one direction. This is probably the best we can do with all the information we have.
               */
        const dragTreshold = 100 // Drag distance values above this will be considered abnormal
        if (Math.abs(dx) > dragTreshold) {
          if (!this.dragErrorX) {
            console.warn(`Calculated horizontal drag distance is out of bounds: ${dx}. This is probably an error. Dragging in horizontal direction will be disabled.`)
            this.dragErrorX = true
          }
          dx = 0
        }
        if (Math.abs(dy) > dragTreshold) {
          if (!this.dragErrorY) {
            console.warn(`Calculated vertical drag distance is out of bounds: ${dy}. This is probably an error. Dragging in vertical direction will be disabled.`)
            this.dragErrorY = true
          }
          dy = 0
        }
        this.shift.x += dx
        this.shift.y += dy
      }
    },

    dragEndListener () {
      if (this.$store.getters['popup/isFixedPositioned']) {
        // Do not store shift values for flexible positioning as they will be erased after each lexical query
        this.settings.uiOptions.items.popupShiftX.setValue(this.shift.x)
        this.settings.uiOptions.items.popupShiftY.setValue(this.shift.y)
      }
    },

    /**
       * This function is called from an `updated()` callback. Because of this, it should never use a `nextTick()`
       * as it might result in an infinite loop of updates: nextTick() causes a popup to be updated, updated()
       * callback is called, that, in turn, calls nextTick() and so on.
       * It seems that calling it even without `nextTick()` is enough for updating a popup dimensions.
       */
    updatePopupDimensions () {
      let time = Date.now()

      if (this.resizeCount >= this.resizeCountMax) {
        // Skip resizing if maximum number reached to avoid infinite loops
        return
      }

      let innerDif = this.$el.querySelector('#alpheios-lexical-data-container').clientHeight - this.$el.querySelector('#alpheios-morph-component').clientHeight

      if (this.heightDm !== 'auto' && innerDif > this.resizeDelta && this.heightValue !== this.maxHeight) {
        this.heightDm = 'auto'
        return
      }

      // Update dimensions only if there was any significant change in a popup size
      if (this.$el.offsetWidth >= this.exactWidth + this.resizeDelta ||
          this.$el.offsetWidth <= this.exactWidth - this.resizeDelta) {
        this.logger.log(`${time}: dimensions update, offsetWidth is ${this.$el.offsetWidth}, previous exactWidth is ${this.exactWidth}`)
        this.exactWidth = this.$el.offsetWidth
        this.widthDm = this.$el.offsetWidth
        this.resizeCount++
        this.logger.log(`Resize counter value is ${this.resizeCount}`)
      }

      if (this.$el.offsetHeight >= this.exactHeight + this.resizeDelta ||
          this.$el.offsetHeight <= this.exactHeight - this.resizeDelta) {
        this.logger.log(`${time}: dimensions update, offsetHeight is ${this.$el.offsetHeight}, previous exactHeight is ${this.exactHeight}`)
        this.exactHeight = this.$el.offsetHeight
        this.heightDm = this.$el.offsetHeight
        this.resizeCount++
        this.logger.log(`Resize counter value is ${this.resizeCount}`)
      }
    },

    resetPopupDimensions () {
      this.logger.log('Resetting popup dimensions')
      // this.contentHeight = 0
      this.resizeCount = 0
      this.widthValue = 0
      this.heightValue = 0
      this.exactWidth = 0
      this.exactHeight = 0
      this.resizedWidth = null
      this.resizedHeight = null
      if (this.$store.getters['popup/isFlexPositioned']) {
        // Reset positioning shift for a `flexible` position of popup only. For a `fixed` position we must retain it
        // so that the popup will open at its last position.
        this.shift = { x: 0, y: 0 }
      }
    },

    attachTrackingClick: function () {
      this.ui.closePopup()
    }
  },

  mounted () {
    if (this.moduleConfig.draggable && this.moduleConfig.resizable) {
      this.interactInstance = interact(this.$el)
        .resizable(this.resizableSettings())
        .draggable(this.draggableSettings())
        .on('dragmove', this.dragMoveListener)
        .on('dragend', this.dragEndListener)
        .on('resizemove', this.resizeListener)
    }

    this.$options.lexrqStartedUnwatch = this.$store.watch((state, getters) => state.app.lexicalRequest.startTime, () => {
      // There is a new request coming in, reset popup dimensions
      this.resetPopupDimensions()
      this.showProviders = false
    })

    this.$options.positioningUnwatch = this.$store.watch((state) => state.popup.positioning, () => {
      if (this.$store.getters['popup/isFlexPositioned']) {
        this.shift = { x: 0, y: 0 }
      } else if (this.$store.getters['popup/isFixedPositioned']) {
        this.shift = {
          x: this.settings.uiOptions.items.popupShiftX.currentValue,
          y: this.settings.uiOptions.items.popupShiftY.currentValue
        }
      }
    })
  },

  beforeDestroy () {
    // Teardown the watch function
    // this.$options.visibleUnwatch()
    this.$options.lexrqStartedUnwatch()
    this.$options.positioningUnwatch()
  },

  updated () {
    if (this.$store.state.popup.visible) {
      let time = Date.now()
      this.logger.log(`${time}: component is updated`)

      let vm = this
      clearTimeout(this.updateDimensionsTimeout)
      let timeoutDuration = 0
      if (this.resizeCount > 1) {
        timeoutDuration = 1000
      }
      this.updateDimensionsTimeout = setTimeout(function () {
        vm.updatePopupDimensions()
      }, timeoutDuration)
    }
  }
}
