//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ViewSetFactory } from 'alpheios-inflection-tables'

import InflFootnote from './infl-footnote.vue'
import Tooltip from './tooltip.vue'

export default {
  name: 'WideInflectionsTableStandardForm',
  inject: ['l10n'],
  components: {
    inflFootnote: InflFootnote,
    alphTooltip: Tooltip
  },
  props: {
    /*
    This component shall receive either a fully initialized (but not necessarily rendered) view prop
    or a standard form data object, which will be used later to construct a standard form view.
    If none is provided, a component will fail to render an inflection table.
     */
    view: {
      type: [Object, Boolean],
      default: false,
      required: false
    },
    standardFormData: {
      type: [Object, Boolean],
      default: false,
      required: false
    },

    // Initial state of the component: collapsed or expanded.
    collapsed: {
      type: [Boolean],
      default: true,
      required: false
    }
  },

  data: function () {
    return {
      standardFormView: null,
      state: {
        view: null,
        standardFormTable: false,
        collapsed: true,
        noSuffixGroupsHidden: true
      },
      classes: {
        fullMorphologyMatch: 'infl-cell--morph-match'
      }
    }
  },

  computed: {
    title: function () {
      return this.view.title || this.standardFormData.title || ''
    },

    additionalTitle: function () {
      return this.view.additionalTitle || this.standardFormData.additionalTitle || ''
    },

    hasInflectionTables: function () {
      return this.isAvailable && !this.state.view.hasPrerenderedTables
    },

    hasPrerenderedTables: function () {
      return this.isAvailable && this.state.view.hasPrerenderedTables
    },

    tableStyles: function () {
      return {
        gridTemplateColumns: `repeat(${this.state.view.wideView.visibleColumnQty + this.state.view.wideView.titleColumnQty}, 1fr)`
      }
    },

    isAvailable: function () {
      return (
        this.state.view &&
        this.state.view.isImplemented &&
        this.state.view.wideView &&
        this.state.view.wideView.rows.length > 0
      )
    }
  },

  methods: {
    getRenderedView: function () {
      if (this.view) {
        // This component has an instance of an initialized view supplied
        return this.view.render()
      } else if (this.standardFormData) {
        // A standard form data is provided. It will be used to create, initialize, and render the corresponding view.
        this.state.standardFormTable = true
        return ViewSetFactory.getStandardForm(this.standardFormData).render()
      } else {
        console.error(`There is neither view nor standard form data is provided. A view will not be rendered`)
      }
    },
    collapse: function () {
      this.state.collapsed = !this.state.collapsed
      if (!this.state.collapsed) {
        // A view has been expanded, we need to check if it needs to be rendered.
        if (!this.state.view || !this.state.view.isRendered) {
          this.state.view = this.getRenderedView()
        }
      }

      if (this.state.view.isImplemented) {
        this.state.view.wideView.collapsed = this.state.collapsed
      }
    },

    hideNoSuffixGroups: function () {
      this.state.view.noSuffixMatchesGroupsHidden(true)
      this.state.noSuffixGroupsHidden = true
    },

    showNoSuffixGroups: function () {
      this.state.view.noSuffixMatchesGroupsHidden(false)
      this.state.noSuffixGroupsHidden = false
    },

    // Cell classes for regular tables
    cellClasses: function (cell) {
      let classes = {
        'infl-cell': true,
        'infl-cell--morph-match': cell.morphologyMatch,
        'infl-cell--hl': cell.highlighted,
        'hidden': cell.hidden
      }

      if (cell.constructor.name === 'HeaderCell') {
        classes['infl-cell--hdr'] = true
        classes[`infl-cell--sp${cell.span}`] = true
      }

      if (cell.constructor.name === 'RowTitleCell') {
        classes['row-title-cell'] = true
        classes['infl-cell--hdr'] = cell.formsColumn
        if (cell.fullWidth) {
          classes['infl-cell--fw'] = true
        } else {
          classes[`infl-cell--sp${cell.span}`] = true
        }
      }

      if (this.state.standardFormTable) {
        // Do not show full morphology matches in an inflection browser
        classes['infl-cell--morph-match'] = false
      }

      return classes
    },

    // Cell classes for pre-rendered tables
    // TODO: merge with `cellClasses()`
    prerenderedCellClasses: function (cell) {
      switch (cell.role) {
        case 'label':
          return 'infl-prdgm-tbl-cell--label'
        case 'data':
          return 'infl-prdgm-tbl-cell--data'
      }
    },

    morphemeClasses: function (morpheme) {
      if (this.state.standardFormTable) {
        return {
          'infl-suff': true
        }
      } else {
        return {
          'infl-suff': true,
          'infl-suff--suffix-match': morpheme.match.suffixMatch,
          'infl-suff--full-match': morpheme.match.fullMatch
        }
      }
    },

    cellMouseOver: function (cell) {
      if (cell.isDataCell) {
        cell.highlightRowAndColumn()
      }
    },

    cellMouseLeave: function (cell) {
      if (cell.isDataCell) {
        cell.clearRowAndColumnHighlighting()
      }
    }
  },

  watch: {
    'view.id': function () {
      this.state.view = this.view
      this.state.noSuffixGroupsHidden = this.state.view.isNoSuffixMatchesGroupsHidden
    },

    collapsed: function (state) {
      if (this.collapsed !== null) {
        this.state.collapsed = state
      }
    }
  },

  mounted: function () {
    // Set a default value by the parent component
    if (this.collapsed !== null) {
      this.state.collapsed = this.collapsed
    }
  }
}
