//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CheckIcon from '@/images/inline-icons/check.svg'
import DeleteIcon from '@/images/inline-icons/delete.svg'
import TextQuoteIcon from '@/images/inline-icons/text-quote.svg'
import CurrentSessionIcon from '@/images/inline-icons/current-session.svg'
import Tooltip from '@/vue/components/tooltip.vue'

export default {
  name: 'WordItemBlock',
  inject: ['l10n', 'app'],
  components: {
    checkIcon: CheckIcon,
    deleteIcon: DeleteIcon,
    textQuoteIcon: TextQuoteIcon,
    currentSessionIcon: CurrentSessionIcon,
    alphTooltip: Tooltip
  },
  props: {
    worditem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      important: false
    }
  },
  mounted () {
    this.important = this.worditem.important
    this.$parent.$on('eventChangeImportant', this.eventChangeImportant)
  },
  computed: {
    itemClasses () {
      return {
        'alpheios-wordlist-language__worditem__active': this.important
      }
    },
    lemmasList () {
      return this.$store.state.app.wordListUpdateTime ? this.worditem.lemmasList.split(',') : []
    }
  },
  methods: {
    changeImportant () {
      this.$emit('changeImportant', this.worditem.targetWord, !this.worditem.important)
      this.important = this.worditem.important
    },
    eventChangeImportant () {
      this.important = this.worditem.important
    },
    selectWordItem () {
      this.app.selectWordItem(this.worditem.languageCode, this.worditem.targetWord)
    },
    deleteItem () {
      this.$emit('deleteItem', this.worditem.targetWord)
    },
    showContexts () {
      this.$emit('showContexts', this.worditem.targetWord)
    },
    setLemmaFilterByClick(lemma) {
      this.$emit('setLemmaFilterByClick', lemma)
    }
  }
}
