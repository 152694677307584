var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data[_vm.type]
    ? _c("span", {
        class: _vm.attributeClass(_vm.type),
        attrs: { "data-feature": _vm.type, "data-grouplevel": _vm.grouplevel },
        domProps: { innerHTML: _vm._s(_vm.decorate(_vm.data, _vm.type)) },
        on: {
          click: function($event) {
            return _vm.sendFeature(_vm.data[_vm.type])
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }