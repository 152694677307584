var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$store.state.toolbar.visible,
          expression: "$store.state.toolbar.visible"
        }
      ],
      staticClass: "alpheios-content alpheios-toolbar alpheios-toolbar--large",
      class: _vm.componentClasses,
      style: _vm.componentStyles,
      attrs: { id: "alpheios-toolbar-inner" }
    },
    [
      _c("div", {
        staticClass: "alpheios-toolbar__drag-handle",
        attrs: { id: "alpheios-toolbar-drag-handle" }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "alpheios-toolbar__lookup-control",
          on: {
            click: function($event) {
              _vm.lookupVisible = !_vm.lookupVisible
            }
          }
        },
        [
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.l10n.getText("LABEL_LOOKUP_CONTROL"),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "alpheios-navbuttons__btn",
                  class: { active: _vm.lookupVisible }
                },
                [_c("lookup-icon")],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "alpheios-toolbar__header",
          class: { expanded: _vm.contentVisible },
          on: {
            click: function($event) {
              _vm.contentVisible = !_vm.contentVisible
            }
          }
        },
        [
          _c("reading-tools-icon", {
            staticClass: "alpheios-toolbar__header-icon"
          }),
          _vm._v(" "),
          _c("collapsed-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.contentVisible,
                expression: "!contentVisible"
              }
            ],
            staticClass: "alpheios-toolbar__header-icon-collapsed"
          }),
          _vm._v(" "),
          _c("expanded-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.contentVisible,
                expression: "contentVisible"
              }
            ],
            staticClass: "alpheios-toolbar__header-icon-expanded"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.lookupVisible,
              expression: "lookupVisible"
            }
          ],
          staticClass: "alpheios-toolbar__lookup"
        },
        [
          _c("lookup", {
            attrs: {
              "name-base": "toolbar",
              "use-page-lang-prefs": true,
              "show-language-settings-group": false
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.contentVisible,
              expression: "contentVisible"
            }
          ],
          staticClass: "alpheios-toolbar__buttons"
        },
        [
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.l10n.getText("TOOLTIP_HELP"),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "alpheios-navbuttons__btn",
                  class: {
                    active: _vm.$store.getters["ui/isActiveTab"]("info")
                  },
                  on: {
                    click: function($event) {
                      return _vm.ui.togglePanelTab("info")
                    }
                  }
                },
                [_c("help-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.l10n.getText("TOOLTIP_INFLECT_BROWSER"),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "alpheios-navbuttons__btn",
                  class: {
                    active: _vm.$store.getters["ui/isActiveTab"](
                      "inflectionsbrowser"
                    )
                  },
                  on: {
                    click: function($event) {
                      return _vm.ui.togglePanelTab("inflectionsbrowser")
                    }
                  }
                },
                [_c("inflections-browser-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.l10n.getText("TOOLTIP_GRAMMAR"),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "alpheios-navbuttons__btn",
                  class: {
                    active: _vm.$store.getters["ui/isActiveTab"]("grammar"),
                    disabled: !_vm.$store.getters["app/hasGrammarRes"]
                  },
                  on: {
                    click: function($event) {
                      return _vm.ui.togglePanelTab("grammar")
                    }
                  }
                },
                [_c("grammar-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.l10n.getText("TOOLTIP_WORDLIST"),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "alpheios-navbuttons__btn",
                  class: {
                    active: _vm.$store.getters["ui/isActiveTab"]("wordlist"),
                    disabled: !_vm.$store.state.app.hasWordListsData
                  },
                  on: {
                    click: function($event) {
                      return _vm.ui.togglePanelTab("wordlist")
                    }
                  }
                },
                [_c("wordlist-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.l10n.getText("TOOLTIP_USER"),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "alpheios-navbuttons__btn",
                  class: {
                    active: _vm.$store.getters["ui/isActiveTab"]("user"),
                    disabled: !_vm.$store.state.auth.enableLogin
                  },
                  on: {
                    click: function($event) {
                      return _vm.ui.togglePanelTab("user")
                    }
                  }
                },
                [_c("user-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                "tooltip-text": _vm.l10n.getText("TOOLTIP_OPTIONS"),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "alpheios-navbuttons__btn",
                  class: {
                    active: _vm.$store.getters["ui/isActiveTab"]("options")
                  },
                  on: {
                    click: function($event) {
                      return _vm.ui.togglePanelTab("options")
                    }
                  }
                },
                [_c("options-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.settings.uiOptions.items.verboseMode.currentValue ===
                    "verbose",
                  expression:
                    "settings.uiOptions.items.verboseMode.currentValue === `verbose`"
                }
              ],
              attrs: {
                "tooltip-text": _vm.l10n.getText("TOOLTIP_STATUS"),
                "tooltip-direction": _vm.tooltipDirection
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "alpheios-navbuttons__btn",
                  class: {
                    active: _vm.$store.getters["ui/isActiveTab"]("status")
                  },
                  on: {
                    click: function($event) {
                      return _vm.ui.togglePanelTab("status")
                    }
                  }
                },
                [_c("status-icon")],
                1
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }