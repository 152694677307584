//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LemmaTranslation',
  props: {
    translations: {
      type: Object,
      required: false
    },
    lemmakey: {
      type: String,
      required: false
    }
  }
}
