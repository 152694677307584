var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$store.state.panel.visible,
          expression: "$store.state.panel.visible"
        }
      ],
      staticClass: "alpheios-panel alpheios-panel--compact alpheios-content",
      class: _vm.rootClasses,
      style: _vm.componentStyles,
      attrs: {
        "data-component": "alpheios-panel",
        "data-resizable": "true",
        id: _vm.panelId
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "alpheios-panel__header",
          attrs: { "data-tab": _vm.currentTab }
        },
        [
          _c(
            "div",
            { staticClass: "alpheios-panel__header-btn-group--start" },
            [
              _c(
                "div",
                {
                  staticClass: "alpheios-panel__header-btn",
                  class: {
                    "alpheios-navbuttons__icon-active":
                      _vm.currentTab === "morphology"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "alpheios-navbuttons__icon-span",
                      on: {
                        click: function($event) {
                          return _vm.changeTab("morphology")
                        }
                      }
                    },
                    [
                      _c("morphology-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showMorphologyIcon,
                            expression: "showMorphologyIcon"
                          }
                        ],
                        staticClass: "alpheios-navbuttons__icon"
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$store.getters["app/defDataReady"] &&
                        _vm.showMainTabIcons,
                      expression:
                        "$store.getters['app/defDataReady'] && showMainTabIcons"
                    }
                  ],
                  staticClass: "alpheios-panel__header-btn",
                  class: {
                    "alpheios-navbuttons__icon-active":
                      _vm.currentTab === "definitions"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "alpheios-navbuttons__icon-span",
                      on: {
                        click: function($event) {
                          return _vm.changeTab("definitions")
                        }
                      }
                    },
                    [
                      _c("definitions-icon", {
                        staticClass: "alpheios-navbuttons__icon"
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$store.state.app.hasInflData &&
                        _vm.showMainTabIcons,
                      expression:
                        "$store.state.app.hasInflData && showMainTabIcons"
                    }
                  ],
                  staticClass:
                    "alpheios-panel__header-btn alpheios-panel__header-btn--infl-data",
                  class: {
                    "alpheios-navbuttons__icon-active":
                      _vm.currentTab === "inflections"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "alpheios-navbuttons__icon-span",
                      on: {
                        click: function($event) {
                          return _vm.changeTab("inflections")
                        }
                      }
                    },
                    [
                      _c("inflections-icon", {
                        staticClass: "alpheios-navbuttons__icon"
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$store.state.app.wordUsageExampleEnabled &&
                        _vm.showMainTabIcons,
                      expression:
                        "$store.state.app.wordUsageExampleEnabled && showMainTabIcons"
                    }
                  ],
                  staticClass: "alpheios-panel__header-btn",
                  class: {
                    "alpheios-navbuttons__icon-active":
                      _vm.currentTab === "wordUsage"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "alpheios-navbuttons__icon-span",
                      on: {
                        click: function($event) {
                          return _vm.changeTab("wordUsage")
                        }
                      }
                    },
                    [
                      _c("word-usage-icon", {
                        staticClass: "alpheios-navbuttons__icon"
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$store.getters["app/hasTreebankData"] &&
                        _vm.showMainTabIcons,
                      expression:
                        "$store.getters['app/hasTreebankData'] && showMainTabIcons"
                    }
                  ],
                  staticClass:
                    "alpheios-panel__header-btn alpheios-panel__header-btn--treebank-data",
                  class: {
                    "alpheios-navbuttons__icon-active":
                      _vm.currentTab === "treebank"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "alpheios-navbuttons__icon-span",
                      on: {
                        click: function($event) {
                          return _vm.changeTab("treebank")
                        }
                      }
                    },
                    [
                      _c("treebank-icon", {
                        staticClass: "alpheios-navbuttons__icon",
                        on: {
                          click: function($event) {
                            return _vm.changeTab("treebank")
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "inflectionsbrowser",
                      expression: "currentTab === 'inflectionsbrowser'"
                    }
                  ],
                  staticClass:
                    "alpheios-panel__header-btn alpheios-navbuttons__icon-active"
                },
                [
                  _c(
                    "span",
                    { staticClass: "alpheios-navbuttons__icon-span" },
                    [
                      _c("inflections-browser-icon", {
                        staticClass: "alpheios-navbuttons__icon"
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "grammar",
                      expression: "currentTab === 'grammar'"
                    }
                  ],
                  staticClass:
                    "alpheios-panel__header-btn  alpheios-navbuttons__icon-active"
                },
                [
                  _c(
                    "span",
                    { staticClass: "alpheios-navbuttons__icon-span" },
                    [
                      _c("grammar-icon", {
                        staticClass: "alpheios-navbuttons__icon"
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "wordlist",
                      expression: "currentTab === 'wordlist'"
                    }
                  ],
                  staticClass:
                    "alpheios-panel__header-btn  alpheios-navbuttons__icon-active"
                },
                [
                  _c(
                    "span",
                    { staticClass: "alpheios-navbuttons__icon-span" },
                    [
                      _c("wordlist-icon", {
                        staticClass: "alpheios-navbuttons__icon"
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "user",
                      expression: "currentTab === 'user'"
                    }
                  ],
                  staticClass:
                    "alpheios-panel__header-btn alpheios-navbuttons__icon-active"
                },
                [
                  _c(
                    "span",
                    { staticClass: "alpheios-navbuttons__icon-span" },
                    [
                      _c("user-icon", {
                        staticClass: "alpheios-navbuttons__icon"
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "options",
                      expression: "currentTab === 'options'"
                    }
                  ],
                  staticClass:
                    "alpheios-panel__header-btn alpheios-navbuttons__icon-active"
                },
                [
                  _c(
                    "span",
                    { staticClass: "alpheios-navbuttons__icon-span" },
                    [
                      _c("options-icon", {
                        staticClass: "alpheios-navbuttons__icon"
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-panel__header-btn-group--end" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLandscape && !_vm.expanded,
                    expression: "!isLandscape && !expanded"
                  }
                ],
                staticClass: "alpheios-panel__header-btn",
                on: { click: _vm.expand }
              },
              [_c("up-icon")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLandscape && _vm.expanded,
                    expression: "!isLandscape && expanded"
                  }
                ],
                staticClass: "alpheios-panel__header-btn",
                on: { click: _vm.contract }
              },
              [_c("down-icon")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLandscape && _vm.leftBtnVisible,
                    expression: "isLandscape && leftBtnVisible"
                  }
                ],
                staticClass: "alpheios-panel__header-btn",
                on: { click: _vm.expandOrContract }
              },
              [_c("left-icon")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLandscape && _vm.rightBtnVisible,
                    expression: "isLandscape && rightBtnVisible"
                  }
                ],
                staticClass: "alpheios-panel__header-btn",
                on: { click: _vm.expandOrContract }
              },
              [_c("right-icon")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "alpheios-panel__close-btn",
                on: { click: _vm.closePanel }
              },
              [_c("close-icon")],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "alpheios-panel__content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("morphology"),
                expression: "$store.getters['ui/isActiveTab']('morphology')"
              }
            ],
            staticClass: "alpheios-panel__tab-panel"
          },
          [
            _vm.$store.getters["app/lexicalRequestInProgress"] &&
            Boolean(this.$store.state.app.currentLanguageName)
              ? _c(
                  "div",
                  { staticClass: "alpheios-popup__definitions--placeholder" },
                  [
                    _c("progress-bar", {
                      attrs: {
                        text: _vm.l10n.getText("PLACEHOLDER_LEX_DATA_LOADING")
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !this.$store.state.app.currentLanguageName &&
                      !_vm.$store.state.app.morphDataReady,
                    expression:
                      "!this.$store.state.app.currentLanguageName && !$store.state.app.morphDataReady"
                  }
                ],
                staticClass: "alpheios-popup__definitions--placeholder"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.l10n.getText("PLACEHOLDER_NO_LANGUAGE_DATA")) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.$store.state.app.morphDataReady &&
                      !_vm.app.hasMorphData() &&
                      Boolean(this.$store.state.app.currentLanguageName),
                    expression:
                      "$store.state.app.morphDataReady && !app.hasMorphData() && Boolean(this.$store.state.app.currentLanguageName)"
                  }
                ],
                staticClass: "alpheios-popup__definitions--placeholder"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.l10n.getText("PLACEHOLDER_NO_MORPH_DATA")) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.$store.state.app.morphDataReady &&
                      _vm.app.hasMorphData(),
                    expression:
                      "$store.state.app.morphDataReady && app.hasMorphData()"
                  }
                ],
                attrs: { id: _vm.lexicalDataContainerID }
              },
              [_c("morph")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.$store.state.app.morphDataReady &&
                      _vm.app.hasMorphData() &&
                      _vm.$store.state.app.providers.length > 0,
                    expression:
                      "$store.state.app.morphDataReady && app.hasMorphData() && $store.state.app.providers.length > 0"
                  }
                ],
                staticClass: "alpheios-popup__providers"
              },
              [
                _c("div", { staticClass: "alpheios-popup__providers-title" }, [
                  _c(
                    "a",
                    {
                      staticClass: "alpheios-popup__providers-link",
                      on: { click: _vm.switchProviders }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.l10n.getText("LABEL_PROVIDERS_CREDITS"))
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showProviders,
                        expression: "showProviders"
                      }
                    ]
                  },
                  _vm._l(_vm.$store.state.app.providers, function(p) {
                    return _c(
                      "div",
                      { staticClass: "alpheios-popup__providers-item" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(p.toString()) +
                            "\n          "
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("definitions"),
                expression: "$store.getters['ui/isActiveTab']('definitions')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__definitions",
            attrs: { "data-alpheios-ignore": "all" }
          },
          [
            _c(
              "div",
              {
                staticClass: "alpheios-lookup__panel",
                class: { "alpheios-landscape": _vm.isLandscape }
              },
              [
                _c("lookup", {
                  attrs: {
                    "name-base": "panel-defs",
                    "show-results-in": "panel"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.$store.getters["app/defDataReady"]
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.formattedShortDefinitions, function(definition) {
                      return _c(
                        "div",
                        {
                          key: definition.ID,
                          staticClass: "alpheios-panel__contentitem"
                        },
                        [
                          _c("shortdef", {
                            attrs: {
                              definition: definition,
                              languageCode: _vm.$store.state.app.languageCode
                            }
                          })
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "alpheios-panel__contentitem alpheios-panel__contentitem-full-definitions",
                      domProps: {
                        innerHTML: _vm._s(_vm.formattedFullDefinitions)
                      }
                    })
                  ],
                  2
                )
              : _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.l10n.getText("PLACEHOLDER_DEFINITIONS")) +
                      "\n      "
                  )
                ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.$store.state.app.hasInflData &&
                  _vm.$store.getters["ui/isActiveTab"]("inflections"),
                expression:
                  "$store.state.app.hasInflData && $store.getters['ui/isActiveTab']('inflections')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__inflections",
            attrs: { id: _vm.inflectionsPanelID, "data-alpheios-ignore": "all" }
          },
          [
            _c("h1", { staticClass: "alpheios-panel__title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.l10n.getText("TITLE_INFLECTIONS_PANEL")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("inflections", { staticClass: "alpheios-panel-inflections" })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"](
                  "inflectionsbrowser"
                ),
                expression:
                  "$store.getters['ui/isActiveTab']('inflectionsbrowser')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__inflectionsbrowser",
            attrs: {
              id: _vm.inflectionsBrowserPanelID,
              "data-alpheios-ignore": "all"
            }
          },
          [
            _c("h1", { staticClass: "alpheios-panel__title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.l10n.getText("TITLE_INFLECTIONS_BROWSER_PANEL")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("inflection-browser")
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("grammar"),
                expression: "$store.getters['ui/isActiveTab']('grammar')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__grammar\n          alpheios-panel__tab-panel--no-padding",
            attrs: { "data-alpheios-ignore": "all" }
          },
          [_c("grammar")],
          1
        ),
        _vm._v(" "),
        _vm.$store.getters["app/hasTreebankData"]
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$store.getters["ui/isActiveTab"]("treebank"),
                    expression: "$store.getters['ui/isActiveTab']('treebank')"
                  }
                ],
                staticClass:
                  "alpheios-panel__tab-panel alpheios-panel__tab__treebank alpheios-panel__tab-panel--no-padding",
                attrs: { "data-alpheios-ignore": "all" }
              },
              [_c("treebank")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("status"),
                expression: "$store.getters['ui/isActiveTab']('status')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__status",
            attrs: { "data-alpheios-ignore": "all" }
          },
          _vm._l(_vm.$store.state.ui.messages, function(message) {
            return _c("div", [
              _c("div", { staticClass: "alpheios-panel__message" }, [
                _vm._v(_vm._s(message))
              ])
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm.$store.state.auth.enableLogin
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$store.getters["ui/isActiveTab"]("user"),
                    expression: "$store.getters['ui/isActiveTab']('user')"
                  }
                ],
                staticClass:
                  "alpheios-panel__tab-panel alpheios-panel__tab__user",
                attrs: { "data-alpheios-ignore": "all" }
              },
              [_c("user-auth")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("wordUsage"),
                expression: "$store.getters['ui/isActiveTab']('wordUsage')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__word-usage"
          },
          [_c("word-usage-examples")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("options"),
                expression: "$store.getters['ui/isActiveTab']('options')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab-panel--options",
            attrs: { "data-alpheios-ignore": "all" }
          },
          [
            _c("ui-settings"),
            _vm._v(" "),
            _c("feature-settings"),
            _vm._v(" "),
            _c("resource-settings"),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "alpheios-button-primary",
                  on: { click: _vm.resetAllOptions }
                },
                [
                  _vm._v(
                    _vm._s(_vm.l10n.getText("LABEL_RESET_OPTIONS")) +
                      "\n        "
                  )
                ]
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("wordlist"),
                expression: "$store.getters['ui/isActiveTab']('wordlist')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__wordlist",
            attrs: { "data-alpheios-ignore": "all" }
          },
          [_c("word-list-panel")],
          1
        )
      ]),
      _vm._v(" "),
      _c("notification-area")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }