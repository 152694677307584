var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.showContext
      ? _c(
          "div",
          { staticClass: "alpheios-wordlist" },
          _vm._l(_vm.languagesList, function(languageCode, langIndex) {
            return _c(
              "div",
              { key: langIndex, staticClass: "alpheios-wordlist-language" },
              [
                _c("word-language", {
                  attrs: { languageCode: languageCode },
                  on: { showContexts: _vm.showContexts }
                })
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showContext
      ? _c(
          "div",
          { staticClass: "alpheios-wordlist-contexts" },
          [
            _c("word-context", {
              attrs: { worditem: _vm.showContextWordItem },
              on: { backToWordList: _vm.backToWordList }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }