var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: _vm.elementIDs.content } }, [
    _vm.$store.state.app.hasInflData
      ? _c(
          "div",
          { staticClass: "alpheios-inflections__content" },
          [
            _vm.selectedView && _vm.selectedView.homonym
              ? _c("word-forms", {
                  attrs: {
                    lexemes: _vm.selectedView.homonym.lexemes,
                    partOfSpeech: _vm.selectedView.constructor.mainPartOfSpeech,
                    targetWord: _vm.selectedView.homonym.targetWord
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.partsOfSpeech.length > 1,
                    expression: "partsOfSpeech.length > 1"
                  }
                ]
              },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.l10n.getMsg("LABEL_INFLECT_SELECT_POFS")))
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.partOfSpeechSelector,
                        expression: "partOfSpeechSelector"
                      }
                    ],
                    staticClass:
                      "alpheios-select alpheios-inflections__view-selector alpheios-text__smallest",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.partOfSpeechSelector = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._l(_vm.partsOfSpeech, function(partOfSpeech) {
                    return _c("option", [_vm._v(_vm._s(partOfSpeech))])
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "alpheios-inflections__actions" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.views.length > 1,
                      expression: "views.length > 1"
                    }
                  ]
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.viewSelector,
                          expression: "viewSelector"
                        }
                      ],
                      staticClass:
                        "alpheios-select alpheios-inflections__view-selector alpheios-text__smallest",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.viewSelector = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.views, function(view) {
                      return _c("option", { domProps: { value: view.id } }, [
                        _vm._v(_vm._s(view.name))
                      ])
                    }),
                    0
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showExplanatoryHint,
                  expression: "showExplanatoryHint"
                }
              ],
              staticClass: "alpheios-inflections__paradigms-expl",
              domProps: {
                innerHTML: _vm._s(
                  _vm.l10n.getMsg("INFLECTIONS_PARADIGMS_EXPLANATORY_HINT", {
                    word: this.$store.state.app.targetWord
                  })
                )
              }
            }),
            _vm._v(" "),
            !_vm.selectedView.hasPrerenderedTables
              ? _c(
                  "div",
                  [
                    _c("main-table-wide-vue", {
                      attrs: { collapsed: false, view: _vm.selectedView }
                    }),
                    _vm._v(" "),
                    _vm.selectedView.linkedViews
                      ? _vm._l(_vm.selectedView.linkedViews, function(
                          linkedView
                        ) {
                          return _c("main-table-wide-vue", {
                            key: linkedView.id,
                            attrs: { collapsed: false, view: linkedView }
                          })
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "alpheios-inflections__footnotes",
                        attrs: { id: _vm.elementIDs.footnotes }
                      },
                      [
                        _vm._l(_vm.footnotes, function(footnote) {
                          return [
                            _c("dt", [_vm._v(_vm._s(footnote.index))]),
                            _vm._v(" "),
                            _c("dd", [_vm._v(_vm._s(footnote.text))])
                          ]
                        })
                      ],
                      2
                    )
                  ],
                  2
                )
              : [
                  _c("prerendered-table-wide", {
                    attrs: { collapsed: false, view: _vm.selectedView }
                  }),
                  _vm._v(" "),
                  _c("sub-tables-wide", {
                    attrs: { collapsed: false, view: _vm.selectedView },
                    on: { navigate: _vm.navigate }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectedView.hasSuppParadigms,
                          expression: "selectedView.hasSuppParadigms"
                        }
                      ],
                      staticClass: "alpheios-inflections__supp-tables"
                    },
                    [
                      _vm._l(_vm.selectedView.suppParadigms, function(
                        paradigm
                      ) {
                        return [
                          _c("supp-tables-wide", {
                            attrs: {
                              "bg-color": _vm.selectedView.hlSuppParadigms
                                ? _vm.selectedView.suppHlColors.get(
                                    paradigm.paradigmID
                                  )
                                : "transparent",
                              data: paradigm
                            },
                            on: { navigate: _vm.navigate }
                          })
                        ]
                      })
                    ],
                    2
                  )
                ],
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectedView.hasCredits,
                    expression: "selectedView.hasCredits"
                  }
                ],
                staticClass: "alpheios-inflections__credits-cont"
              },
              [
                _c(
                  "h3",
                  { staticClass: "alpheios-inflections__credits-title" },
                  [_vm._v(_vm._s(_vm.l10n.getMsg("INFLECTIONS_CREDITS_TITLE")))]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "alpheios-inflections__credits-text",
                  domProps: { innerHTML: _vm._s(_vm.selectedView.creditsText) }
                })
              ]
            )
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }