var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alpheios-navbuttons alpheios-navbuttons--compact" },
    [
      _c(
        "alph-tooltip",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasMorphologyData,
              expression: "hasMorphologyData"
            }
          ],
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_MORPHOLOGY"),
            tooltipDirection: "top"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: {
                active: _vm.$store.getters["ui/isActiveTab"]("morphology")
              },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("morphology")
                }
              }
            },
            [_c("morphology-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$store.getters["app/defDataReady"],
              expression: "$store.getters['app/defDataReady']"
            }
          ],
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_DEFINITIONS"),
            tooltipDirection: "top"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: {
                active: _vm.$store.getters["ui/isActiveTab"]("definitions")
              },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("definitions")
                }
              }
            },
            [_c("definitions-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$store.state.app.hasInflData,
              expression: "$store.state.app.hasInflData"
            }
          ],
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_INFLECT"),
            tooltipDirection: "top"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: {
                active: _vm.$store.getters["ui/isActiveTab"]("inflections")
              },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("inflections")
                }
              }
            },
            [_c("inflections-icon")],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }