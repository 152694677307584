var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alpheios-word-usage-header-filters" }, [
    _c(
      "p",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.gettingResult,
            expression: "gettingResult"
          }
        ],
        staticClass: "alpheios-word-usage-get-data-progress"
      },
      [_vm._v(_vm._s(_vm.l10n.getText("WORDUSAGE_GETTING_RESULT")))]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showHeader && !_vm.collapsedHeader,
            expression: "showHeader && !collapsedHeader"
          }
        ]
      },
      [
        _vm.authorsList
          ? _c("div", { staticClass: "alpheios-word-usage-filters-select" }, [
              _c("p", { staticClass: "alpheios-word-usage-filter-title" }, [
                _vm._v(_vm._s(_vm.l10n.getText("WORDUSAGE_FOCUS_AUTHOR")))
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedAuthor,
                      expression: "selectedAuthor"
                    }
                  ],
                  staticClass:
                    "alpheios-select alpheios-word-usage-header-filter-select",
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedAuthor = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function($event) {
                        return _vm.getResults("author")
                      }
                    ]
                  }
                },
                _vm._l(_vm.lastAuthorsList, function(authorItem, authorIndex) {
                  return _c(
                    "option",
                    {
                      key: authorIndex,
                      class: { "alpheios-select-disabled-option": !authorItem },
                      domProps: { value: authorItem }
                    },
                    [_vm._v(_vm._s(_vm.calcTitle(authorItem, "author")))]
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.filteredWorkList
          ? _c("div", { staticClass: "alpheios-word-usage-filters-select" }, [
              _c("p", { staticClass: "alpheios-word-usage-filter-title" }, [
                _vm._v(_vm._s(_vm.l10n.getText("WORDUSAGE_FOCUS_WORK")))
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedTextWork,
                      expression: "selectedTextWork"
                    }
                  ],
                  staticClass:
                    "alpheios-select alpheios-word-usage-header-filter-select",
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedTextWork = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function($event) {
                        return _vm.getResults("textWork")
                      }
                    ]
                  }
                },
                _vm._l(_vm.filteredWorkList, function(workItem, workIndex) {
                  return _c(
                    "option",
                    {
                      key: workIndex,
                      class: { "alpheios-select-disabled-option": !workItem },
                      domProps: { value: workItem }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.calcTitle(workItem, "textwork")) +
                          "\n            "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }