//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Constants } from 'alpheios-data-models'

import WideTable from './inflections-table-wide.vue'
// Modules support
import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'InflectionStandardForms',
  components: {
    wideTable: WideTable
  },
  storeModules: ['app'], // Store modules that are required by this component
  mixins: [DependencyCheck],
  lexrqStartedUnwatch: null,

  data: function () {
    return {
      // As we cannot reference `Constants` in template during rendering we have to reassign them this way
      constants: {
        LANG_LATIN: Constants.LANG_LATIN,
        LANG_GREEK: Constants.LANG_GREEK
      },
      views: new Map(),
      collapsed: {
        // Vue.js currently cannot track Maps or Symbol props so we have to do it this way:
        [Constants.LANG_LATIN.toString()]: true,
        [Constants.LANG_GREEK.toString()]: true
      },
      htmlElements: {
        content: undefined
      },
      languageId: this.$store.state.app.currentLanguageID,
      inflBrowserTablesCollapsed: true
    }
  },

  watch: {
    languageId: function (newValue, oldValue) {
      if (oldValue) {
        this.collapsed[oldValue.toString()] = true
      }
      if (newValue) {
        this.languageId = newValue
        this.collapsed[newValue.toString()] = false
      }
    }
  },

  methods: {
    collapseLanguage: function (languageID) {
      const language = languageID.toString()
      for (const lang of Object.keys(this.collapsed)) {
        this.collapsed[lang] = (lang === language) ? !this.collapsed[language] : true
      }
    }
  },

  mounted: function () {
    if (this.languageId) {
      // Set a group that will be opened initially
      this.collapsed[this.languageId.toString()] = false
    }
    if (typeof this.$el.querySelector === 'function') {
      this.htmlElements.content = this.$el
    }

    this.$options.lexrqStartedUnwatch = this.$store.watch((state) => state.app.lexicalRequest.startTime, () => {
      this.inflBrowserTablesCollapsed = true
    })
  },

  beforeDestroy () {
    // Teardown the watch function
    this.$options.lexrqStartedUnwatch()
  }
}
