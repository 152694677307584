var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alpheios-panel__options-item" }, [
    _c("label", { staticClass: "alpheios-setting__label" }, [
      _vm._v(_vm._s(_vm.l10n.getMsg("LABEL_RESKIN_SETTINGS")) + ":")
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "alpheios-setting__button-group alpheios-setting__control"
      },
      [
        _c(
          "button",
          {
            class: { active: _vm.activeButton === 12 },
            on: {
              click: function($event) {
                return _vm.changeFontSize(12)
              }
            }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.l10n.getMsg("FONTSIZE_TEXT_SMALL")) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: { active: _vm.activeButton === 16 },
            on: {
              click: function($event) {
                return _vm.changeFontSize(16)
              }
            }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.l10n.getMsg("FONTSIZE_TEXT_MEDIUM")) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: { active: _vm.activeButton === 20 },
            on: {
              click: function($event) {
                return _vm.changeFontSize(20)
              }
            }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.l10n.getMsg("FONTSIZE_TEXT_LARGE")) +
                "\n    "
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }