//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClearFilters from '@/images/inline-icons/clear-filters.svg'
import GoIcon from '@/images/inline-icons/go-icon.svg'
import Tooltip from '@/vue/components/tooltip.vue'

export default {
  name: 'WordFilterPanel',
  inject: ['app', 'l10n'],
  components: {
    clearFiltersIcon: ClearFilters,
    goIcon: GoIcon,
    alphTooltip: Tooltip
  },
  props: {
    clickedLemma: {
      type: String,
      required: false
    },
    wordExactForms: {
      type: Array,
      required: false,
      default: []
    },
    wordLemmaForms: {
      type: Array,
      required: false,
      default: []
    },
    clearFilters: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      selectedFilterBy: null,
      selectedExactForm: null,
      selectedLemma: null,
      typeFiltersList: [
        { value: null, title: this.l10n.getText('WORDLIST_FILTER_PLACEHOLDER'), disabled: true },
        { value: 'byCurrentSession', title: this.l10n.getText('WORDLIST_FILTER_BYCURRENTSESSION'), onChange: true },
        { value: 'byImportant', title: this.l10n.getText('WORDLIST_FILTER_BYIMPORTANT'), onChange: true },
        { value: 'byExactForm',
          title: this.l10n.getText('WORDLIST_FILTER_BYWORDFORM_FULL'),
          onClick: true, showTextInput: true,
          textInputPlaceholder: this.l10n.getText('WORDLIST_FILTER_BYWORDFORM_FULL_PLACEHOLDER')
        },
        { value: 'byLemma',
          title: this.l10n.getText('WORDLIST_FILTER_BYLEMMA_FULL'),
          onClick: true, showTextInput: true,
          textInputPlaceholder: this.l10n.getText('WORDLIST_FILTER_BYLEMMA_FULL_PLACEHOLDER')
        }
      ],
      textInput: null,
      shownVariantsSelect: false,
      markLayout: {
        start: '<span class="alpheios-select-input-filter-part">',
        end: '</span>'
      }
    }
  },
  computed: {
    currentTypeFilter () {
      let curFilter = this.selectedFilterBy ? this.typeFiltersList.find(typeFilter => typeFilter.value === this.selectedFilterBy) : null
      return curFilter
    },
    currentClickedLemma () {
      if (this.clickedLemma) {
        this.setClickedLemmaFilter()
      }
      return true
    },
    wordExactFormsFiltered () {
      if (this.selectedFilterBy === 'byExactForm') {
        if (this.textInput && this.textInput.length > 0) {
          return this.wordExactForms.filter(exactForm => exactForm.indexOf(this.textInput) > -1).map(exactForm => {
            let startIndex = exactForm.indexOf(this.textInput)
            return exactForm.substr(0, startIndex) + this.markLayout.start + this.textInput + this.markLayout.end + exactForm.substr(startIndex + this.textInput.length)
          })
        } else {
          return this.wordExactForms
        }
      }
      return []
    },
    wordLemmaFormsFiltered () {
      if (this.selectedFilterBy === 'byLemma') {
        if (this.textInput && this.textInput.length > 0) {
          return this.wordLemmaForms.filter(lemmaForm => lemmaForm.indexOf(this.textInput) > -1).map(lemmaForm => {
            let startIndex = lemmaForm.indexOf(this.textInput)
            return lemmaForm.substr(0, startIndex) + this.markLayout.start + this.textInput + this.markLayout.end + lemmaForm.substr(startIndex + this.textInput.length)
          })
        } else {
          return this.wordLemmaForms
        }
      }
      return []
    }
  },
  watch: {
    clearFilters (value) {
      this.selectedFilterBy = null
      this.textInput = null
    }
  },
  methods: {
    changedFilterBy () {
      if (this.currentTypeFilter.onChange) {
        this.$emit('changedFilterBy', this.selectedFilterBy)
      } else {
        this.clearFilteringText()
      }
    },
    selectExactForm (selectedExactForm) {
      let formattedExactForm = selectedExactForm
      this.textInput = formattedExactForm.replace(this.markLayout.start,'').replace(this.markLayout.end,'')

      this.clickFilterBy()
    },
    selectLemmaForm (selectedLemmaForm) {
      let formattedLemmaForm = selectedLemmaForm
      this.textInput = formattedLemmaForm.replace(this.markLayout.start,'').replace(this.markLayout.end,'')

      this.clickFilterBy()
    },
    clickFilterBy () {
      if (this.currentTypeFilter && this.currentTypeFilter.onClick && this.textInput) {
        if (this.selectedFilterBy === 'byExactForm' && !this.wordExactForms.includes(this.textInput)) {
          return
        }
        if (this.selectedFilterBy === 'byLemma' && !this.wordLemmaForms.includes(this.textInput)) {
          return
        }
        this.$emit('changedFilterBy', this.selectedFilterBy, this.textInput)
        this.shownVariantsSelect = false
      }
    },
    clearFiltering () {
      this.selectedFilterBy = null
      this.textInput = null
      this.clearFilterEvent()
    },
    clearFilteringText () {
      this.textInput = null
      this.clearFilterEvent()
    },
    clearFilterEvent () {
      this.$emit('changedFilterBy', null)
    },
    setClickedLemmaFilter () {
      this.selectedFilterBy = 'byLemma'
      this.textInput = this.clickedLemma.trim()
      this.clickFilterBy()
      this.$emit('clearClickedLemma')
    },
    filterVariants () {
      if (this.textInput && this.textInput.length > 0) {
        this.shownVariantsSelect = true
      } else {
        this.shownVariantsSelect = false
      }
    },
    hideAutocomplete () {
      setTimeout(() => {
        this.shownVariantsSelect = false
      }, 300)
    }
  }
}
