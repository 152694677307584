var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alpheios-ib" }, [
    _c("div", { staticClass: "alpheios-ib__lang-cont" }, [
      _c(
        "div",
        {
          staticClass: "alpheios-ib__title alpheios-clickable",
          class: { open: !_vm.collapsed[_vm.constants.LANG_LATIN.toString()] },
          on: {
            click: function($event) {
              return _vm.collapseLanguage(_vm.constants.LANG_LATIN)
            }
          }
        },
        [
          _vm._v("\n      Latin Inflection Browser\n      "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.collapsed[_vm.constants.LANG_LATIN.toString()],
                  expression: "collapsed[constants.LANG_LATIN.toString()]"
                }
              ]
            },
            [_vm._v("[+]")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.collapsed[_vm.constants.LANG_LATIN.toString()],
                  expression: "!collapsed[constants.LANG_LATIN.toString()]"
                }
              ]
            },
            [_vm._v("[-]")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.collapsed[_vm.constants.LANG_LATIN.toString()],
              expression: "!collapsed[constants.LANG_LATIN.toString()]"
            }
          ]
        },
        [
          _c("div", { staticClass: "alpheios-ib__pofs-title" }, [
            _vm._v("Nouns")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_noun_view",
                title: "Noun declension"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title" }, [
            _vm._v("Adjectives")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_adjective_view",
                title: "Adjective declension"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title" }, [
            _vm._v("Verbs")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l2" }, [
            _vm._v("Regular verbs")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l3" }, [
            _vm._v("Sorted by...")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_conjugation_mood_voice_view",
                title: "Conjugation-Mood-Voice"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_conjugation_voice_mood_view",
                title: "Conjugation-Voice-Mood"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_mood_conjugation_voice_view",
                title: "Mood-Conjugation-Voice"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_mood_voice_conjugation_view",
                title: "Mood-Coice-Conjugation"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_voice_conjugation_mood_view",
                title: "Voice-Conjugation-Mood"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_voice_mood_conjugation_view",
                title: "Voice-Mood-Conjugation"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l3" }, [
            _vm._v("Other Forms")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_participle_view",
                title: "Participle"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_infinitive_view",
                title: "Infinitive"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_imperative_view",
                title: "Imperative"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_supine_view",
                title: "Supine"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l2" }, [
            _vm._v("Irregular verbs")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "sum",
                title: "Sum (esse,fui,futurus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_voice_view",
                form: "fero",
                title: "Fero (ferre, tuli, latus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "malo",
                title: "Malo (malle, malui)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "nolo",
                title: "Nolo (nolle, nolui)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "volo",
                title: "Volo (velle, volui)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "eo",
                title: "Eo (ire, ivi(ii), itus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "absum",
                title: "Absum (abesse, afui, afuturus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "adsum",
                title: "Adsum (adesse, adfui, adfuturus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "dēsum",
                title: "Dēsum (dēesse, dēfui, dēfuturus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "insum",
                title: "Insum (inesse, infui, infuturus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "intersum",
                title: "Intersum (interesse, interfui, interfuturus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "obsum",
                title: "Obsum (obesse, obfui, obfuturus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "possum",
                title: "Possum (posse, potui)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "prosum",
                title: "Prosum (prodesse, profui, profuturus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "praesum",
                title: "Praesum (praeesse, praefui, praefuturus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "subsum",
                title: "Subsum (subesse, subfui, subfuturus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "supersum",
                title: "Supersum (superesse, superfui, superfuturus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_voice_view",
                form: "queo",
                title: "Queo (quire, quivi(ii), quitus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "nequeo",
                title: "Nequeo (nequire, nequivi(ii), nequitus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_voice_view",
                form: "adeo",
                title: "Adeo (adire, adivi(ii), aditus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_voice_view",
                form: "ineo",
                title: "Ineo (inire, inivi(ii), initus)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_LATIN,
                viewID: "latin_verb_irregular_view",
                form: "veneo",
                title: "Veneo (venire, venivi(ii), venitus)"
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "alpheios-ib__lang-cont" }, [
      _c(
        "div",
        {
          staticClass: "alpheios-ib__title alpheios-clickable",
          class: { open: !_vm.collapsed[_vm.constants.LANG_GREEK.toString()] },
          on: {
            click: function($event) {
              return _vm.collapseLanguage(_vm.constants.LANG_GREEK)
            }
          }
        },
        [
          _vm._v("\n      Greek Inflection Browser\n      "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.collapsed[_vm.constants.LANG_GREEK.toString()],
                  expression: "collapsed[constants.LANG_GREEK.toString()]"
                }
              ]
            },
            [_vm._v("[+]")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.collapsed[_vm.constants.LANG_GREEK.toString()],
                  expression: "!collapsed[constants.LANG_GREEK.toString()]"
                }
              ]
            },
            [_vm._v("[-]")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.collapsed[_vm.constants.LANG_GREEK.toString()],
              expression: "!collapsed[constants.LANG_GREEK.toString()]"
            }
          ]
        },
        [
          _c("div", { staticClass: "alpheios-ib__pofs-title" }, [
            _vm._v("Nouns")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_noun_view",
                title: "Noun declension"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_noun_simplified_view",
                title: "Noun declension (simplified)"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title" }, [
            _vm._v("Adjectives")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_adjective_view",
                title: "Adjective declension"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_adjective_simplified_view",
                title: "Adjective declension (simplified)"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title" }, [
            _vm._v("Pronouns")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_person_pronoun_view",
                form: "νώ",
                title: "Personal Pronoun Declension"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_person_gender_pronoun_view",
                form: "ἡμᾶς",
                title: "Reflexive Pronoun Declension"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_gender_pronoun_view",
                form: "ἀλλήλᾱ",
                title: "Reciprocal Pronoun Declension"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_lemma_gender_pronoun_view",
                form: "τούτω",
                title: "Demonstrative Pronoun Declension"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_gender_pronoun_view",
                form: "οἷς",
                title: "Relative Pronoun Declension"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_gender_pronoun_view",
                form: "ὥτινε",
                title: "General Relative Pronoun Declension"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_gender_pronoun_view",
                form: "τίνε",
                title: "Interrogative Pronoun Declension"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_gender_pronoun_view",
                form: "τινοῖν",
                title: "Indefinite Pronoun Declension"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_gender_pronoun_view",
                form: "αὐτά",
                title: "Intensive Pronoun Declension"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title" }, [
            _vm._v("Articles")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_article_view",
                form: "τοῦ",
                title: "Article Declension"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title" }, [
            _vm._v("Numerals")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_numeral_view",
                form: "δύο",
                title: "Numeral Declension"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title" }, [
            _vm._v("Verb Paradigms")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l2" }, [
            _vm._v("ω Verbs")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l3" }, [
            _vm._v("Regular ω Verbs")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm1",
                title: "ω-Verbs: Present System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm2",
                title: "ω-Verbs: Present System Middle-Passive"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm3",
                title: "Future System (without contraction)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm4",
                title:
                  "Future System (Active and Middle) with contraction in -έω"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm5",
                title: "Future System (Active) with contraction in -άω"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm6",
                title: "Strong (2nd) Aorist System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm7",
                title: "Strong (2nd) Aorist System Middle"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm8",
                title: "Weak (1st) Aorist System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm9",
                title: "Weak (1st) Aorist System Middle"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm10",
                title: "Aorist Passive System"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm11",
                title: "Perfect Active System"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm12",
                title:
                  "Perfect System Middle-Passive: indicative, infinitive, participle"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm13",
                title:
                  "Perfect System Middle-Passive: periphrastic subjunctive, optative, imperative"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm14",
                title:
                  "Perfect System Middle-Passive: simple subjunctive, optative, imperative"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm15",
                title: "Pluperfect Middle-Passive Indicative"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm16",
                title: "Future Perfect Indicative, Infinitive, Participle"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l3" }, [
            _vm._v("Athematic Perfects")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm17",
                title:
                  "Athematic Perfects - ἵστημι (in addition to forms from ἕστηκα)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm17b",
                title:
                  "Athematic Perfects - θνῄσκω (in addition to forms from τέθνηκα)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm17c",
                title:
                  "Athematic Perfects - δέδια (in addition to forms from δέδοικα)"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l3" }, [
            _vm._v("Present System of Contract Verbs (-εω, -αω, -οω)")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm18",
                title: "Present System Active of Contract Verbs in -έω"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm19",
                title:
                  "Present System Active of Contract Verbs in -έω (monosyllabic stems)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm20",
                title: "Present System Middle-Passive of Contract Verbs in -έω"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm21",
                title:
                  "Present System Middle-Passive of Contract Verbs in -έω (monosyllabic stem)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm22",
                title: "Present System Active of Contract Verbs in -άω"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm23",
                title:
                  "Present System Active of Contract Verbs in -άω (with η contraction)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm24",
                title: "Present System Middle-Passive of Contract Verbs in -άω"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm25",
                title:
                  "Present System Middle-Passive of Contract Verbs in -άω (with η contraction)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm26",
                title: "Present System Active of Contract Verbs in -όω"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm27",
                title: "Present System Middle-Passive of Contract Verbs in -όω"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l2" }, [
            _vm._v("μι Verbs")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l3" }, [
            _vm._v("τίθημι")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm28",
                title: "τίθημι: Present System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm29",
                title: "τίθημι: Present System Middle-Passive"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm30",
                title: "τίθημι: Aorist System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm31",
                title: "τίθημι: Aorist System Middle"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l3" }, [
            _vm._v("ἵημι")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm32",
                title: "ἵημι: Present System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm33",
                title: "ἵημι: Present System Middle-Passive"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm34",
                title: "ἵημι: Aorist System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm35",
                title: "ἵημι: Aorist System Middle"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l3" }, [
            _vm._v("δίδωμι")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm36",
                title: "δίδωμι: Present System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm37",
                title: "δίδωμι: Present System Middle-Passive"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm38",
                title: "δίδωμι: Aorist System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm39",
                title: "δίδωμι: Aorist System Middle"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l3" }, [
            _vm._v("ἵστημι")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm40",
                title: "ἵστημι: Present System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm41",
                title: "ἵστημι: Present System Middle-Passive"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm42",
                title: "ἵστημι: (Athematic/Intransitive) Aorist System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l3" }, [
            _vm._v("Other")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm43",
                title: "δύναμαι: Present System Middle-Passive"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm43b",
                title: "ἐπίσταμαι: Present System Middle-Passive"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm44",
                title: "δείκνυμι: Present System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm45",
                title: "δείκνυμι: Present System Middle-Passive"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm46",
                title: "εἰμί (be): Present System and Future"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm47",
                title: "εἶμι (go): Present System"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm48",
                title: "φημί: Present System"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm49",
                title: "βαίνω: Aorist System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm50",
                title: "γιγνώσκω: Aorist System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm51",
                title: "ἁλίσκομαι: Aorist System"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm52",
                title: "δύω: Aorist System Active"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_paradigm_view",
                paradigmID: "verbpdgm53",
                title: "οἶδα: Perfect System"
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-ib__pofs-title-l2" }, [
            _vm._v("Participles")
          ]),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm54",
                title:
                  "Participles in -ων, -ουσα, -ον (present and future active, uncontracted)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm55",
                title:
                  "Participles in -ῶν, -οῦσα, -οῦν (present and future active, ε- and ο-contract)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm56",
                title:
                  "Participles in -ῶν, -ῶσα, -ῶν (present and future active, α-contract)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm57",
                title:
                  "Participles in -ών, -οῦσα, -όν (strong aorist active; present of εἰμί and εἶμι)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm58",
                title: "Participles in -ᾱς, -ᾶσα, -αν (weak aorist active)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm59",
                title:
                  "Participles in -άς, -ᾶσα, -άν (μι-verb present and aorist active)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm60",
                title: "Participles in -είς, -εῖσα, -έν"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm61",
                title: "Participles in -ούς, -οῦσᾰ, -όν (μι-verb active)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm62",
                title: "Participles in -ύς, -ῦσᾰ, -ύν (μι-verb active)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm63",
                title: "Participles in -ώς, -υῖᾰ, -ός (perfect active)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm64",
                title: "Participles in -ώς, -ῶσα, -ός (some athematic perfects)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm65",
                title:
                  "Participles in -μενος, -μένη, -μενον (all middle-passive and middle except perfect)"
              }
            }
          }),
          _vm._v(" "),
          _c("wide-table", {
            attrs: {
              collapsed: _vm.inflBrowserTablesCollapsed,
              "standard-form-data": {
                langID: _vm.constants.LANG_GREEK,
                viewID: "greek_verb_participle_paradigm_view",
                paradigmID: "verbpdgm66",
                title:
                  "Participles in -μένος, -μένη, -μένον (perfect middle-passive)"
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }