//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BackIcon from '@/images/inline-icons/back.svg'
import Tooltip from '@/vue/components/tooltip.vue'
import WordTqSourceBlock from '@/vue/components/word-list/word-tq-source-block.vue'

export default {
  name: 'WordContextBlock',
  components: {
    backIcon: BackIcon,
    alphTooltip: Tooltip,
    wordTqSource: WordTqSourceBlock
  },
  inject: ['l10n'],
  props: {
    worditem: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedContext () {
      return this.worditem.formattedContext
    },
    sourcesList () {
      return Object.keys(this.formattedContext)
    }
  },
  methods: {
    backToWordList () {
      this.$emit('backToWordList')
    }
  }
}
