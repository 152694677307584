var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alpheios-morph" },
    _vm._l(_vm.lexemes, function(lex, index) {
      return _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLexeme(lex),
              expression: "showLexeme(lex)"
            }
          ],
          key: lex.lemma.ID
        },
        [
          lex
            ? _c("div", { class: _vm.morphClass(lex) }, [
                _c(
                  "div",
                  { staticClass: "alpheios-morph__features" },
                  [
                    _vm._l(_vm.allLemmas(lex), function(lemma, lemmaIndex) {
                      return _c(
                        "div",
                        { staticClass: "principal_parts" },
                        [
                          lemmaIndex === 0 && _vm.lexemes.length > 1
                            ? _c("span", { staticClass: "lemma_index" }, [
                                _vm._v(_vm._s(index + 1))
                              ])
                            : lemmaIndex > 0 && _vm.lexemes.length > 1
                            ? _c("span", { staticClass: "lemma_index_spacer" })
                            : _vm._e(),
                          _vm._v(" "),
                          !lemma.principalParts.includes(lemma.word)
                            ? _c(
                                "h4",
                                {
                                  staticClass: "alpheios-morph__groupitem",
                                  attrs: {
                                    lang: _vm.languageCode(lemma.languageID)
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(lemma.word) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "h4",
                            { staticClass: "alpheios-morph__groupitem" },
                            _vm._l(lemma.principalParts, function(part) {
                              return _c(
                                "span",
                                {
                                  staticClass: "alpheios-morph__listitem",
                                  attrs: {
                                    lang: _vm.languageCode(lemma.languageID)
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(part) +
                                      "\n            "
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("inflectionattribute", {
                            attrs: {
                              data: lemma.features,
                              decorators: ["brackets"],
                              type: _vm.types.pronunciation
                            }
                          }),
                          _vm._v(" "),
                          lemma.features &&
                          (_vm.getFeature(lemma, "frequency") ||
                            _vm.getFeature(lemma, "age") ||
                            _vm.getFeature(lemma, "area") ||
                            _vm.getFeature(lemma, "geo"))
                            ? _c(
                                "span",
                                { staticClass: "feature_extras" },
                                [
                                  _c("inflectionattribute", {
                                    attrs: {
                                      data: _vm.featureList(
                                        lemma,
                                        ["age", "area", "geo", "frequency"],
                                        "extras"
                                      ),
                                      type: "extras"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          lemma.features && _vm.getFeature(lemma, "source")
                            ? _c(
                                "span",
                                { staticClass: "feature_source" },
                                [
                                  _c("inflectionattribute", {
                                    attrs: {
                                      data: lemma.features,
                                      decorators: ["link", "brackets"],
                                      type: _vm.types.source
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    lex.lemma.features
                      ? _c(
                          "div",
                          { staticClass: "alpheios-morph__morphdata" },
                          [
                            _c(
                              "span",
                              { staticClass: "alpheios-morph__pofs" },
                              [
                                _c("inflectionattribute", {
                                  attrs: {
                                    data: lex.lemma.features,
                                    type: _vm.types.grmCase
                                  }
                                }),
                                _vm._v(" "),
                                _c("inflectionattribute", {
                                  attrs: {
                                    data: lex.lemma.features,
                                    type: _vm.types.gender
                                  }
                                }),
                                _vm._v(" "),
                                _c("inflectionattribute", {
                                  attrs: {
                                    data: lex.lemma.features,
                                    type: _vm.types.part
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("inflectionattribute", {
                              attrs: {
                                data: lex.lemma.features,
                                decorators: ["parenthesize"],
                                type: _vm.types.kind
                              }
                            }),
                            _vm._v(" "),
                            _c("inflectionattribute", {
                              attrs: {
                                data: lex.lemma.features,
                                decorators: ["appendtype"],
                                type: _vm.types.declension
                              }
                            }),
                            _vm._v(" "),
                            _c("inflectionattribute", {
                              attrs: {
                                data: lex.lemma.features,
                                decorators: ["appendtype"],
                                type: _vm.types.conjugation
                              }
                            }),
                            _vm._v(" "),
                            _c("inflectionattribute", {
                              attrs: {
                                data: lex.lemma.features,
                                decorators: ["brackets"],
                                type: _vm.types.note
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.definitions(lex).length > 0
                  ? _c(
                      "div",
                      { staticClass: "alpheios-morph__definition_list" },
                      _vm._l(_vm.definitions(lex), function(
                        definition,
                        dindex
                      ) {
                        return _c(
                          "div",
                          {
                            key: definition.ID,
                            staticClass: "alpheios-morph__definition",
                            attrs: { "data-lemmakey": lex.lemma.ID }
                          },
                          [
                            _vm.definitions(lex).length > 1
                              ? _c(
                                  "span",
                                  { staticClass: "definition_index" },
                                  [_vm._v(_vm._s(_vm.definitionIndex(dindex)))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("shortdef", {
                              attrs: { definition: definition }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.translations &&
                _vm.translations[lex.lemma.ID] &&
                _vm.translations[lex.lemma.ID].glosses &&
                _vm.translations[lex.lemma.ID].glosses.length > 0
                  ? _c(
                      "div",
                      { staticClass: "alpheios-morph__translation_list" },
                      [
                        _c("lemmatranslation", {
                          attrs: {
                            lemmakey: lex.lemma.ID,
                            translations: _vm.translations
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.inflections(lex).length > 0
                  ? _c(
                      "div",
                      { staticClass: "alpheios-morph__inflections" },
                      _vm._l(_vm.inflections(lex), function(inflset, ifindex) {
                        return _c(
                          "div",
                          { staticClass: "alpheios-morph__inflset" },
                          [
                            _vm.inflections(lex).length > 1
                              ? _c("span", { staticClass: "inflset_index" }, [
                                  _vm._v(_vm._s(ifindex + 1) + ".")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "alpheios-morph__forms" },
                              [
                                inflset.groupingKey.prefix
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "alpheios-morph__formtext",
                                        attrs: {
                                          lang: _vm.languageCode(
                                            lex.lemma.languageID
                                          ),
                                          "data-feature": "prefix",
                                          "data-grouplevel": "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(inflset.groupingKey.prefix) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "alpheios-morph__formtext",
                                    attrs: {
                                      lang: _vm.languageCode(
                                        lex.lemma.languageID
                                      ),
                                      "data-feature": "stem",
                                      "data-grouplevel": "1"
                                    }
                                  },
                                  [_vm._v(_vm._s(inflset.groupingKey.stem))]
                                ),
                                _vm._v(" "),
                                inflset.groupingKey.suffix
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "alpheios-morph__formtext",
                                        attrs: {
                                          lang: _vm.languageCode(
                                            lex.lemma.languageID
                                          ),
                                          "data-feature": "suffix",
                                          "data-grouplevel": "1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " -" +
                                            _vm._s(inflset.groupingKey.suffix)
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "alpheios-morph__inflfeatures"
                                  },
                                  [
                                    !_vm.featureMatch(
                                      lex.lemma.features[_vm.types.part],
                                      inflset.groupingKey[_vm.types.part]
                                    )
                                      ? _c("inflectionattribute", {
                                          attrs: {
                                            data: inflset.groupingKey,
                                            grouplevel: 1,
                                            type: _vm.types.part
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    inflset.groupingKey.declension &&
                                    !_vm.featureMatch(
                                      inflset.groupingKey.declension,
                                      lex.lemma.features.declension
                                    )
                                      ? _c("inflectionattribute", {
                                          attrs: {
                                            data: inflset.groupingKey,
                                            decorators: ["appendtype"],
                                            grouplevel: 1,
                                            type: _vm.types.declension
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm._l(inflset.inflections, function(group) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass: "alpheios-morph__inflgroup"
                                    },
                                    [
                                      group.groupingKey.isCaseInflectionSet
                                        ? _c(
                                            "span",
                                            [
                                              _c("inflectionattribute", {
                                                attrs: {
                                                  data: group.groupingKey,
                                                  decorators: ["abbreviate"],
                                                  grouplevel: 2,
                                                  type: _vm.types.number
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("inflectionattribute", {
                                                attrs: {
                                                  data: group.groupingKey,
                                                  decorators: ["abbreviate"],
                                                  grouplevel: 2,
                                                  type: _vm.types.tense
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(group.inflections, function(
                                        nextGroup
                                      ) {
                                        return _c(
                                          "div",
                                          { class: _vm.groupClass(group) },
                                          [
                                            group.groupingKey
                                              .isCaseInflectionSet
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("inflectionattribute", {
                                                      attrs: {
                                                        data:
                                                          nextGroup.groupingKey,
                                                        decorators: [
                                                          "abbreviate"
                                                        ],
                                                        grouplevel: 3,
                                                        type: _vm.types.tense
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("inflectionattribute", {
                                                      attrs: {
                                                        data:
                                                          nextGroup.groupingKey,
                                                        decorators: [
                                                          "abbreviate"
                                                        ],
                                                        grouplevel: 3,
                                                        type: _vm.types.voice
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm._l(
                                              nextGroup.inflections,
                                              function(infl) {
                                                return _c(
                                                  "div",
                                                  {
                                                    class: _vm.groupClass(group)
                                                  },
                                                  [
                                                    _c("inflectionattribute", {
                                                      attrs: {
                                                        data: infl.groupingKey,
                                                        decorators: [
                                                          "abbreviate"
                                                        ],
                                                        grouplevel: 4,
                                                        type: _vm.types.grmCase
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    !_vm.featureMatch(
                                                      infl.groupingKey[
                                                        _vm.types.gender
                                                      ],
                                                      lex.lemma.features[
                                                        _vm.types.gender
                                                      ]
                                                    )
                                                      ? _c(
                                                          "inflectionattribute",
                                                          {
                                                            attrs: {
                                                              data:
                                                                infl.groupingKey,
                                                              decorators: [
                                                                "parenthesize",
                                                                "abbreviate"
                                                              ],
                                                              grouplevel: 4,
                                                              type:
                                                                _vm.types.gender
                                                            }
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c("inflectionattribute", {
                                                      attrs: {
                                                        data: infl.groupingKey,
                                                        decorators: [
                                                          "abbreviate"
                                                        ],
                                                        grouplevel: 4,
                                                        type:
                                                          _vm.types.comparison
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("inflectionattribute", {
                                                      attrs: {
                                                        data: infl.groupingKey,
                                                        decorators: [
                                                          "appendtype",
                                                          "abbreviate"
                                                        ],
                                                        grouplevel: 4,
                                                        type: _vm.types.person
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    !group.groupingKey
                                                      .isCaseInflectionSet
                                                      ? _c(
                                                          "inflectionattribute",
                                                          {
                                                            attrs: {
                                                              data:
                                                                infl.groupingKey,
                                                              decorators: [
                                                                "abbreviate"
                                                              ],
                                                              grouplevel: 4,
                                                              type:
                                                                _vm.types.number
                                                            }
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !group.groupingKey
                                                      .isCaseInflectionSet
                                                      ? _c(
                                                          "inflectionattribute",
                                                          {
                                                            attrs: {
                                                              data:
                                                                infl.groupingKey,
                                                              decorators: [
                                                                "abbreviate"
                                                              ],
                                                              grouplevel: 4,
                                                              type:
                                                                _vm.types.tense
                                                            }
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !group.groupingKey
                                                      .isCaseInflectionSet
                                                      ? _c(
                                                          "inflectionattribute",
                                                          {
                                                            attrs: {
                                                              data:
                                                                infl.groupingKey,
                                                              decorators: [
                                                                "abbreviate"
                                                              ],
                                                              grouplevel: 4,
                                                              type:
                                                                _vm.types.mood
                                                            }
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !group.groupingKey
                                                      .isCaseInflectionSet
                                                      ? _c(
                                                          "inflectionattribute",
                                                          {
                                                            attrs: {
                                                              data:
                                                                infl.groupingKey,
                                                              decorators: [
                                                                "abbreviate"
                                                              ],
                                                              grouplevel: 4,
                                                              type:
                                                                _vm.types.voice
                                                            }
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      infl.inflections,
                                                      function(item) {
                                                        return _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "inflectionattribute",
                                                              {
                                                                attrs: {
                                                                  data: item,
                                                                  decorators: [
                                                                    "parenthesize"
                                                                  ],
                                                                  type:
                                                                    "dialect"
                                                                }
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "inflectionattribute",
                                                              {
                                                                attrs: {
                                                                  data: item,
                                                                  type:
                                                                    "example"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      })
                                    ],
                                    2
                                  )
                                })
                              ],
                              2
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }