var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alpheios-word-usage" }, [
    _c(
      "div",
      {
        staticClass: "alpheios_word_usage_list_title",
        attrs: { "data-alpheios-ignore": "all" }
      },
      [
        _vm._v(_vm._s(_vm.targetWord) + "\n    "),
        _vm.showHeaderFilters
          ? _c(
              "span",
              {
                staticClass: "alpheios-word-usage-header-show-link",
                on: {
                  click: function($event) {
                    _vm.collapsedHeader = !_vm.collapsedHeader
                  }
                }
              },
              [_vm._v(" (" + _vm._s(_vm.collapsedHeaderTitle) + ")")]
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "alpheios-word-usage-header",
        attrs: { "data-alpheios-ignore": "all" }
      },
      [
        _c("word-usage-examples-filters", {
          attrs: {
            collapsedHeader: _vm.collapsedHeader,
            showHeader: _vm.showHeader
          },
          on: {
            filterCurrentByAuthor: _vm.filterCurrentByAuthor,
            getMoreResults: _vm.getMoreResults,
            getAllResults: _vm.getAllResults
          }
        }),
        _vm._v(" "),
        _c("word-usage-examples-sorting", {
          attrs: {
            showHeader: _vm.showHeader,
            collapsedHeader: _vm.collapsedHeader,
            hasSelectedAuthor: _vm.hasSelectedAuthor,
            hasSelectedTextWork: _vm.hasSelectedTextWork,
            reloadSorting: _vm.reloadSorting
          },
          on: { changedSortBy: _vm.changedSortBy }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.showWordUsageExampleItems
      ? _c(
          "div",
          { staticClass: "alpheios_word_usage_list_mainblock" },
          [
            _vm.wordUsageListSorted.length > 0
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "alpheios-word-usage__examples-show-sources-btn alpheios-button-primary",
                      attrs: { "data-alpheios-ignore": "all" },
                      on: { click: _vm.changeShowDataSource }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.l10n.getText("WORDUSAGE_SHOW_SOURCE_LINKS")
                          ) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "alpheios-word-usage__examples",
                      class: {
                        "alpheios-word-usage__examples--sources-visible":
                          _vm.showDataSource
                      }
                    },
                    [
                      _vm._l(_vm.wordUsageListSorted, function(wordUsageItem) {
                        return [
                          _c("a", {
                            staticClass:
                              "alpheios-word-usage__examples-source-link-large",
                            attrs: {
                              href: wordUsageItem.source,
                              target: "_blank"
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.formattedFullCit(wordUsageItem)
                              )
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "alpheios-word-usage__examples-pre"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(wordUsageItem.prefix) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "alpheios-word-usage__examples-target-word",
                            domProps: {
                              innerHTML: _vm._s(wordUsageItem.normalizedText)
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "alpheios-word-usage__examples-post"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(wordUsageItem.suffix) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ]
              : [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.l10n.getText("WORDUSAGE_NO_RESULTS")) +
                      "\n    "
                  )
                ]
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.provider,
            expression: "provider"
          }
        ],
        staticClass: "alpheios-word_usage_list__provider"
      },
      [_vm._v("\n    " + _vm._s(_vm.provider) + "\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }