var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alpheios-feature-options__cont" },
    [
      _c("setting", {
        attrs: {
          classes: ["alpheios-feature-options-item"],
          data: _vm.settings.featureOptions.items.enableWordUsageExamples
        },
        on: { change: _vm.featureOptionChanged }
      }),
      _vm._v(" "),
      _c("setting", {
        attrs: {
          classes: ["alpheios-feature-options-item"],
          data: _vm.settings.featureOptions.items.wordUsageExamplesON
        },
        on: { change: _vm.featureOptionChanged }
      }),
      _vm._v(" "),
      _c("setting", {
        attrs: {
          classes: ["alpheios-feature-options-item"],
          data: _vm.settings.featureOptions.items.wordUsageExamplesAuthMax
        },
        on: { change: _vm.featureOptionChanged }
      }),
      _vm._v(" "),
      _c("setting", {
        attrs: {
          classes: ["alpheios-feature-options-item"],
          data: _vm.settings.featureOptions.items.wordUsageExamplesMax
        },
        on: { change: _vm.featureOptionChanged }
      }),
      _vm._v(" "),
      _c("setting", {
        attrs: {
          classes: ["alpheios-feature-options-item"],
          data: _vm.settings.featureOptions.items.enableLemmaTranslations
        },
        on: { change: _vm.featureOptionChanged }
      }),
      _vm._v(" "),
      _c("setting", {
        attrs: {
          classes: ["alpheios-feature-options-item"],
          data: _vm.settings.featureOptions.items.locale
        },
        on: { change: _vm.featureOptionChanged }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }