//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WordLanguagePanel from '@/vue/components/word-list/word-language-panel.vue'
import WordContextPanel from '@/vue/components/word-list/word-context-panel.vue'

export default {
  name: 'WordListPanel',
  inject: ['app'],
  components: {
    wordLanguage: WordLanguagePanel,
    wordContext: WordContextPanel
  },
  data () {
    return {
      showContextWordItem: null
    }
  },
  computed: {
    languagesList () {
      this.showContextWordItem = null
      return this.$store.state.app.wordListUpdateTime && Object.keys(this.wordLists).length > 0 ? Object.keys(this.wordLists) : []
    },
    wordLists () {
      return this.$store.state.app.wordListUpdateTime ? this.app.getAllWordLists() : []
    },
    showContext () {
      return Boolean(this.showContextWordItem)
    }
  },
  methods: {
    showContexts (targetWord, wordListLanguageCode) {
      this.showContextWordItem = this.wordLists[wordListLanguageCode].getWordItem(targetWord)
    },
    backToWordList () {
      this.showContextWordItem = null
    }
  }
}
