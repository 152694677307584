var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("login"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.$store.state.auth.isAuthenticated,
              expression: "this.$store.state.auth.isAuthenticated"
            }
          ],
          staticClass: "alpheios-user-auth__user-info-box"
        },
        [
          _c("div", { staticClass: "alpheios-user-auth__user-info-item-box" }, [
            _c(
              "div",
              { staticClass: "alpheios-user-auth__user-info-item-name" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.l10n.getMsg("AUTH_PROFILE_NICKNAME_LABEL")) +
                    ":\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "alpheios-user-auth__user-info-item-value" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      this.$store.state.auth.userNickName
                        ? this.$store.state.auth.userNickName
                        : "—"
                    ) +
                    "\n      "
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }