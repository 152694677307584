//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Login from './login.vue'
import CloseIcon from '@/images/inline-icons/x-close.svg'

export default {
  name: 'UserAuth',
  inject: {
    l10n: 'l10n',
    auth: { from: 'auth', default: null } // This module is options
  },
  components: {
    login: Login,
    closeIcon: CloseIcon
  }
}
