//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
  This is a mobile version of a panel
   */
// JS imports
import interact from 'interactjs'
// Support libraries
import Platform from '@/lib/utility/platform.js'
// Vue components
import NotificationArea from '@/vue/components//notification-area.vue'
import Inflections from './inflections.vue'
import ShortDef from './shortdef.vue'
import Grammar from './grammar.vue'
import Morph from './morph.vue'
import Treebank from './treebank.vue'
import InflectionBrowser from './inflections-browser.vue'
import Lookup from './lookup.vue'
import ResourceSettings from './resource-settings.vue'
import FeatureSettings from './feature-settings.vue'
import UISettings from './ui-settings.vue'
import UserAuth from './user-auth.vue'
import WordUsageExamples from '@/vue/components/word-usage-examples/word-usage-examples.vue'
import { Definition } from 'alpheios-data-models'
import WordListPanel from '@/vue/components/word-list/word-list-panel.vue'
import ProgressBar from '@/vue/components/progress-bar.vue'
// Embeddable SVG icons
import CloseIcon from '@/images/inline-icons/x-close.svg'
import UpIcon from '@/images/inline-icons/chevron-up.svg'
import DownIcon from '@/images/inline-icons/chevron-down.svg'
import LeftIcon from '@/images/inline-icons/chevron-left.svg'
import RightIcon from '@/images/inline-icons/chevron-right.svg'
import MorphologyIcon from '@/images/inline-icons/language.svg'
import DefinitionsIcon from '@/images/inline-icons/definitions.svg'
import InflectionsIcon from '@/images/inline-icons/inflections.svg'
import WordUsageIcon from '@/images/inline-icons/usage-examples-icon1.svg'
import InflectionsBrowserIcon from '@/images/inline-icons/inflections-browser.svg'
import UserIcon from '@/images/inline-icons/user.svg'
import OptionsIcon from '@/images/inline-icons/options.svg'
import GrammarIcon from '@/images/inline-icons/resources.svg'
import WordlistIcon from '@/images/inline-icons/wordlist-icon.svg'
import TreebankIcon from '@/images/inline-icons/sitemap.svg'

// Vue directives
import { directive as onClickaway } from '../directives/clickaway.js'

// Modules support
import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'PanelCompact',
  // API modules that are required for this component
  inject: {
    app: 'app',
    ui: 'ui',
    language: 'language',
    l10n: 'l10n',
    settings: 'settings',
    auth: 'auth'
  },
  storeModules: ['app', 'ui', 'panel', 'auth'], // Store modules that are required by this component
  mixins: [DependencyCheck],
  components: {
    progressBar: ProgressBar,
    notificationArea: NotificationArea,
    inflections: Inflections,
    inflectionBrowser: InflectionBrowser,
    shortdef: ShortDef,
    grammar: Grammar,
    morph: Morph,
    treebank: Treebank,
    userAuth: UserAuth,
    closeIcon: CloseIcon,
    lookup: Lookup,
    uiSettings: UISettings,
    resourceSettings: ResourceSettings,
    featureSettings: FeatureSettings,
    wordListPanel: WordListPanel,
    wordUsageExamples: WordUsageExamples,
    upIcon: UpIcon,
    downIcon: DownIcon,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    morphologyIcon: MorphologyIcon,
    definitionsIcon: DefinitionsIcon,
    inflectionsIcon: InflectionsIcon,
    wordUsageIcon: WordUsageIcon,
    inflectionsBrowserIcon: InflectionsBrowserIcon,
    userIcon: UserIcon,
    optionsIcon: OptionsIcon,
    grammarIcon: GrammarIcon,
    wordlistIcon: WordlistIcon,
    treebankIcon: TreebankIcon
  },
  directives: {
    onClickaway: onClickaway
  },
  // Custom props
  // An HTML element that contains alpheios CSS custom props
  customPropEl: undefined,
  customPropStyle: undefined,
  baseTextSize: undefined,
  scaledTextSize: undefined,
  currentTextSize: undefined,
  panelVisibilityUnwatch: undefined,
  panelPositionUnwatch: undefined,
  panelOrientationUnwatch: undefined,

  data: function () {
    return {
      panelId: 'alpheios-panel-inner',
      menuVisible: false,
      inflectionsPanelID: 'alpheios-panel__inflections-panel',
      inflectionsBrowserPanelID: 'alpheios-panel__inflections-browser-panel',
      lexicalDataContainerID: 'alpheios-panel__lex-data-container',
      panelLeftPadding: 0,
      panelRightPadding: 0,
      scrollPadding: 0,
      // Whether the panel is expanded full width
      expanded: false,
      resized: false,
      showProviders: false
    }
  },

  // `positionClassVariants` is a custom property. This is to prent Vue from attaching reactivity to it.
  positionClassVariants: {
    left: 'alpheios-panel--left',
    right: 'alpheios-panel--right'
  },

  computed: {
    currentTab () {
      return this.$store.state.ui.activeTab
    },
    showMainTabIcons () {
      let mainTabArray = ['morphology', 'definitions', 'inflections', 'wordUsage', 'treebank']
      return mainTabArray.includes(this.currentTab)
    },
    showMorphologyIcon () {
      return this.$store.state.app.morphDataReady && this.app.hasMorphData() && (this.showMainTabIcons || this.currentTab === 'grammar')
    },
    rootClasses () {
      let classes = []

      /*
      Position classes are needed for landscape orientations only as only those
      can have compact panel attached to either left or right.
      For portrait-oriented screens a compact panel will occupy full width.
       */
      if (this.isLandscape) {
        classes.push(this.$options.positionClassVariants[this.$store.state.panel.position])
      }

      if (this.expanded) {
        classes.push('alpheios-panel--expanded')
      }
      return classes
    },

    componentStyles: function () {
      return {
        // It shall have a z-index higher than that of a popup
        zIndex: this.ui.zIndex + 10
      }
    },

    isLandscape: function () {
      // Have to use store prop to keep orientation reactive
      return this.$store.state.panel.orientation === Platform.orientations.LANDSCAPE
    },

    isAttachedToLeft: function () {
      return this.$store.state.panel.position === 'left'
    },

    isAttachedToRight: function () {
      return this.$store.state.panel.position === 'right'
    },

    leftBtnVisible: function () {
      return (this.isAttachedToLeft && this.expanded) || (this.isAttachedToRight && !this.expanded)
    },

    rightBtnVisible: function () {
      return (this.isAttachedToRight && this.expanded) || (this.isAttachedToLeft && !this.expanded)
    },

    hasMorphologyData: function () {
      return this.$store.state.app.morphDataReady && this.app.hasMorphData()
    },

    additionalStylesTootipCloseIcon: function () {
      return {
        top: '2px',
        right: '50px'
      }
    },

    verboseMode () {
      return this.settings.uiOptions.items.verboseMode.currentValue === `verbose`
    },

    formattedShortDefinitions () {
      let definitions = []
      if (this.$store.getters['app/defDataReady'] && this.$store.state.app.homonymDataReady) {
        for (const lexeme of this.app.getHomonymLexemes()) {
          if (lexeme.meaning.shortDefs.length > 0) {
            definitions.push(...lexeme.meaning.shortDefs)
          } else if (Object.entries(lexeme.lemma.features).length > 0) {
            definitions.push(new Definition(this.l10n.getMsg('TEXT_NOTICE_NO_DEFS_FOUND'), 'en-US', 'text/plain', lexeme.lemma.word))
          }
        }
      }
      return definitions
    },

    formattedFullDefinitions () {
      let content = ''
      if (this.$store.getters['app/defDataReady'] && this.$store.state.app.homonymDataReady) {
        for (const lexeme of this.app.getHomonymLexemes()) {
          content += `<h3>${lexeme.lemma.word}</h3>\n`
          for (const fullDef of lexeme.meaning.fullDefs) {
            content += `${fullDef.text}<br>\n`
          }
        }
      }
      return content
    },

    providersLinkText: function () {
      return this.showProviders ? this.l10n.getText('LABEL_POPUP_HIDECREDITS') : this.l10n.getText('LABEL_POPUP_SHOWCREDITS')
    }
  },
  methods: {
    swapPosition () {
      this.isAttachedToLeft ? this.setPosition('right') : this.setPosition('left')
    },

    setPosition (position) {
      this.settings.uiOptions.items.panelPosition.setValue(position)
      this.$store.commit('panel/setPosition', position)
    },

    squeezePage () {
      let propName = this.isAttachedToRight ? 'padding-right' : 'padding-left'
      document.documentElement.style.setProperty(propName, '50%')
      document.body.classList.add('alpheios-layout-landscape-open-panel')
    },

    unsqueezePage () {
      document.documentElement.style.removeProperty('padding-left')
      document.documentElement.style.removeProperty('padding-right')
      document.body.classList.remove('alpheios-layout-landscape-open-panel')
    },

    contentOptionChanged: function (name, value) {
      this.app.contentOptionChange(name, value)
    },

    resetAllOptions: function () {
      this.app.resetAllOptions()
    },

    expand () {
      this.expanded = true
    },

    contract () {
      this.expanded = false
    },

    expandOrContract () {
      this.expanded = !this.expanded
    },

    closePanel () {
      this.ui.closePanel()
      // Reset a scaled font size
      document.documentElement.style.removeProperty('--alpheios-base-text-size')

      // Close the menu if it was open during the panel closing
      this.menuVisible = false
    },

    gestureMoveListener: function (event) {
      const computedFontSize = Math.round(this.$options.scaledTextSize * event.scale)
      if (Math.abs(computedFontSize - this.$options.currentTextSize) > 1) {
        // Update element's style only when size change is greater than 1px to avoid extra redraws
        this.$options.currentTextSize = computedFontSize
        document.documentElement.style.setProperty('--alpheios-base-text-size', `${this.$options.currentTextSize}px`, 'important')
      }
    },

    gestureEndListener: function () {
      this.$options.scaledTextSize = this.$options.currentTextSize
    },

    switchProviders: function () {
      this.showProviders = !this.showProviders
    },

    changeTab (tabName) {
      this.ui.changeTab(tabName)
    }
  },

  mounted: function () {
    this.$options.customPropEl = document.querySelector('html')
    this.$options.customPropStyle = window.getComputedStyle(this.$options.customPropEl, null)
    this.$options.baseTextSize = this.$options.customPropStyle.getPropertyValue('font-size')
    // Remove pixel units from the value string
    this.$options.baseTextSize = this.$options.baseTextSize.replace(/px/, '')
    this.$options.scaledTextSize = this.$options.baseTextSize
    this.$options.currentTextSize = this.$options.baseTextSize

    interact(`#${this.panelId}`).gesturable({})
      .on('gesturemove', this.gestureMoveListener.bind(this))
      .on('gestureend', this.gestureEndListener.bind(this))

    this.$options.panelVisibilityUnwatch = this.$store.watch((state) => state.panel.visible, (newValue) => {
      if (this.app.platform.isMobile) {
        if (newValue && this.isLandscape) {
          // Panel became visible
          this.squeezePage()
        } else {
          // Panel was hidden
          this.unsqueezePage()
        }
      }
    })

    this.$options.panelPositionUnwatch = this.$store.watch((state) => state.panel.position, () => {
      if (this.app.platform.isMobile && this.isLandscape && this.$store.state.panel.visible) {
        // Clear previous values first, then set new ones
        this.unsqueezePage()
        this.squeezePage()
      }
    })

    this.$options.panelOrientationUnwatch = this.$store.watch((state) => state.panel.orientation, () => {
      this.unsqueezePage()
      if (this.app.platform.isMobile && this.isLandscape && this.$store.state.panel.visible) {
        this.squeezePage()
      }
    })
  },

  beforeDestroy () {
    // Teardown the watcher
    this.$options.panelVisibilityUnwatch()
    this.$options.panelPositionUnwatch()
    this.$options.panelOrientationUnwatch()
  }
}
