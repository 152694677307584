//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'

export default {
  name: 'Setting',
  components: {
    Multiselect
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    classes: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  /*
  TODO: A temporary solution to force Vue.js to redraw when settings are changed
       (as settings objects of `app` are not reactive).
       This better be handled by refactoring settings objects and integrating them into a Vuex store.
   */
  data: function () {
    return {
      dataModel: undefined
    }
  },
  computed: {
    selected: {
      get: function () {
        let rv
        if (typeof this.dataModel.currentTextValue === 'function' && this.dataModel.boolean !== true && this.dataModel.number !== true) {
          rv = this.dataModel.currentTextValue()
        } else if (this.dataModel.boolean === true) {
          rv = this.dataModel.currentValue
        } else if (this.dataModel.number === true) {
          rv = parseInt(this.dataModel.currentValue)
        }
        return rv
      },
      set: function (newValue) {
        this.$emit('change', this.data.name, newValue)
        this.dataModel = this.data // To force Vue.js to redraw
      }
    },
    values: function () {
      return this.data && !this.data.number && this.data.textValues ? this.data.textValues() : []
    },
    checkboxLabel: function () {
      return (this.data && this.data.textValues) ? this.data.textValues()[0].text : ''
    }
  },
  methods: {
    checkboxClick () {
      if (this.data.boolean === true) {
        this.selected = !this.selected
      }
    },
    checkNumberField () {
      if (this.dataModel.number && this.dataModel.minValue) {
        if (this.selected < this.dataModel.minValue) {
          this.selected = this.dataModel.minValue
        }
      }
      if (this.dataModel.number && this.dataModel.maxValue) {
        if (this.selected > this.dataModel.maxValue) {
          this.selected = this.dataModel.maxValue
        }
      }
    }
  },
  created: function () {
    this.dataModel = this.data
  }
}
