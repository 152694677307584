var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.attachTrackingClick,
          expression: "attachTrackingClick"
        },
        {
          name: "show",
          rawName: "v-show",
          value: this.$store.state.popup.visible,
          expression: "this.$store.state.popup.visible"
        }
      ],
      ref: "popup",
      staticClass: "alpheios-popup alpheios-content",
      style: _vm.componentStyles,
      attrs: { id: "alpheios-popup-inner", "data-alpheios-ignore": "all" }
    },
    [
      _c("div", { staticClass: "alpheios-popup__header" }, [
        _c(
          "div",
          { staticClass: "alpheios-popup__logo" },
          [_c("logo-icon", { staticClass: "alpheios-logo-on-dark" })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "alpheios-popup__toolbar-buttons" },
          [
            _c(
              "alph-tooltip",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$store.getters["app/defDataReady"],
                    expression: "$store.getters['app/defDataReady']"
                  }
                ],
                attrs: {
                  tooltipText: _vm.l10n.getText("TOOLTIP_SHOW_DEFINITIONS"),
                  tooltipDirection: "bottom-wide"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "alpheios-popup__toolbar-top__btn" },
                  [
                    _c("definitions-icon", {
                      staticClass: "alpheios-navbuttons__icon",
                      on: {
                        click: function($event) {
                          return _vm.ui.showPanelTab("definitions")
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "alph-tooltip",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$store.state.app.hasInflData,
                    expression: "$store.state.app.hasInflData"
                  }
                ],
                attrs: {
                  tooltipText: _vm.l10n.getText("TOOLTIP_SHOW_INFLECTIONS"),
                  tooltipDirection: "bottom-wide"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "alpheios-popup__toolbar-top__btn" },
                  [
                    _c("inflections-icon", {
                      staticClass: "alpheios-navbuttons__icon",
                      on: {
                        click: function($event) {
                          return _vm.ui.showPanelTab("inflections")
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "alph-tooltip",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$store.state.app.wordUsageExampleEnabled,
                    expression: "$store.state.app.wordUsageExampleEnabled"
                  }
                ],
                attrs: {
                  tooltipText: _vm.l10n.getText("TOOLTIP_SHOW_USAGEEXAMPLES"),
                  tooltipDirection: "bottom-wide"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "alpheios-popup__toolbar-top__btn" },
                  [
                    _c("word-usage-icon", {
                      staticClass: "alpheios-navbuttons__icon",
                      on: {
                        click: function($event) {
                          return _vm.ui.showPanelTab("wordUsage")
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "alph-tooltip",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$store.getters["app/hasTreebankData"],
                    expression: "$store.getters['app/hasTreebankData']"
                  }
                ],
                attrs: {
                  tooltipText: _vm.l10n.getText("TOOLTIP_TREEBANK"),
                  tooltipDirection: "bottom-wide"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "alpheios-popup__toolbar-top__btn" },
                  [
                    _c("treebank-icon", {
                      staticClass: "alpheios-navbuttons__icon",
                      on: {
                        click: function($event) {
                          return _vm.ui.showPanelTab("treebank")
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "alpheios-popup__close-btn",
                on: { click: _vm.ui.closePopup }
              },
              [_c("close-icon")],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "alpheios-popup__body" }, [
        _c("div", { staticClass: "alpheios-popup__toolbar" }, [
          _c(
            "div",
            {
              staticClass: "alpheios-popup__toolbar-text",
              attrs: { lang: _vm.$store.state.app.languageCode }
            },
            [
              _c(
                "h3",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$store.state.app.selectedText,
                      expression: "$store.state.app.selectedText"
                    }
                  ],
                  staticClass: "alpheios-popup__toolbar-selection",
                  attrs: { lang: _vm.$store.state.app.languageCode }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$store.state.app.selectedText) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$store.state.app.languageName && _vm.verboseMode,
                      expression: "$store.state.app.languageName && verboseMode"
                    }
                  ],
                  staticClass: "alpheios-popup__toolbar-word",
                  attrs: { lang: "en" }
                },
                [
                  _vm._v(
                    "\n          (" +
                      _vm._s(_vm.$store.state.app.languageName) +
                      ")\n        "
                  )
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "alpheios-popup__content" }, [
          _vm.$store.getters["app/lexicalRequestInProgress"] && !_vm.noLanguage
            ? _c(
                "div",
                { staticClass: "alpheios-popup__definitions--placeholder" },
                [
                  _c("progress-bar", {
                    attrs: {
                      text: _vm.l10n.getText("PLACEHOLDER_LEX_DATA_LOADING")
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.noLanguage && !_vm.$store.state.app.morphDataReady,
                  expression: "noLanguage && !$store.state.app.morphDataReady"
                }
              ],
              staticClass: "alpheios-popup__definitions--placeholder"
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.l10n.getText("PLACEHOLDER_NO_LANGUAGE_DATA")) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.$store.state.app.morphDataReady &&
                    !_vm.app.hasMorphData() &&
                    !_vm.noLanguage,
                  expression:
                    "$store.state.app.morphDataReady && !app.hasMorphData() && !noLanguage"
                }
              ],
              staticClass: "alpheios-popup__definitions--placeholder"
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.l10n.getText("PLACEHOLDER_NO_MORPH_DATA")) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.$store.state.app.morphDataReady &&
                    _vm.app.hasMorphData(),
                  expression:
                    "$store.state.app.morphDataReady && app.hasMorphData()"
                }
              ],
              attrs: { id: _vm.lexicalDataContainerID }
            },
            [_c("morph", { attrs: { id: _vm.morphComponentID } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.$store.state.app.morphDataReady &&
                    _vm.app.hasMorphData() &&
                    _vm.$store.state.app.providers.length > 0,
                  expression:
                    "$store.state.app.morphDataReady && app.hasMorphData() && $store.state.app.providers.length > 0"
                }
              ],
              staticClass: "alpheios-popup__providers"
            },
            [
              _c("div", { staticClass: "alpheios-popup__providers-title" }, [
                _c(
                  "a",
                  {
                    staticClass: "alpheios-popup__providers-link",
                    on: { click: _vm.switchProviders }
                  },
                  [_vm._v(_vm._s(_vm.l10n.getText("LABEL_PROVIDERS_CREDITS")))]
                )
              ]),
              _vm._v(" "),
              _vm.showProviders
                ? _c(
                    "div",
                    _vm._l(_vm.$store.state.app.providers, function(p) {
                      return _c(
                        "div",
                        { staticClass: "alpheios-popup__providers-item" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(p.toString()) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("notification-area")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }