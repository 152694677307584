//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClearFilters from '@/images/inline-icons/clear-filters.svg'
import Tooltip from '@/vue/components/tooltip.vue'

export default {
  name: 'WordUsageExamplesSorting',
  inject: ['app', 'l10n'],
  components: {
    clearFiltersIcon: ClearFilters,
    alphTooltip: Tooltip
  },
  props: {
    collapsedHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    hasSelectedAuthor: {
      type: Boolean,
      required: false,
      default: false
    },
    hasSelectedTextWork: {
      type: Boolean,
      required: false,
      default: false
    },
    reloadSorting: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      selectedSortBy: null,
      typeSortingList: [
        { value: null, title: this.l10n.getText('WORDUSAGE_SORT_BY_PLACEHOLDER') },
        { value: 'byTextWork', title: this.l10n.getText('WORDUSAGE_SORT_BY_TEXTWORK') },
        { value: 'byPrefix', title: this.l10n.getText('WORDUSAGE_SORT_BY_PREFIX') },
        { value: 'bySuffix', title: this.l10n.getText('WORDUSAGE_SORT_BY_SUFFIX') }
      ],
      calctypeSortingList: null
    }
  },

  watch: {
    reloadSorting (value) {
      if (this.availableSortBy) {
        if (this.hasSelectedTextWork) {
          this.selectedSortBy = this.typeSortingList[2].value
          this.calctypeSortingList = this.typeSortingList.slice(2, 4)
        } else if (this.hasSelectedAuthor) {
          this.selectedSortBy = this.typeSortingList[1].value
          this.calctypeSortingList = this.typeSortingList.slice(1, 4)
        } else {
          let result = this.typeSortingList.slice(2, 4)
          result.unshift(this.typeSortingList[0])
          this.selectedSortBy = this.typeSortingList[0].value
          this.calctypeSortingList = result
        }
      } else {
        this.selectedSortBy = null
      }
      this.changedSortBy()
    }
  },
  computed: {
    availableSortBy () {
      return this.$store.state.app.wordUsageExamplesReady && this.app.wordUsageExamples.wordUsageExamples && this.app.wordUsageExamples.wordUsageExamples.length > 1
    },
    finalTypeSortingList () {
      return this.reloadSorting ? this.calctypeSortingList : null
    }
  },
  methods: {
    changedSortBy () {
      this.$emit('changedSortBy', this.selectedSortBy)
    },
    clearSorting () {
      this.selectedSortBy = null
      this.changedSortBy()
    }
  }
}
