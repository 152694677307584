//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'WideSupplementalInflectionsTable',
  inject: ['l10n'],
  props: {
    /*
         An object that represents a wide version of a table, consists of array of rows.
         Each rows consists of an array of cells.
        */
    data: {
      type: [Object, Boolean],
      required: true
    },

    bgColor: {
      type: [String],
      required: true
    }
  },

  methods: {
    cellClasses: function (cell) {
      if (cell.role === 'label') {
        return 'infl-prdgm-tbl-cell--label'
      }

      /*
          If it is a data cell, we need to figure out if this is a cell with a full match and
          highlight it accordingly. A full match is a cell which matches all features of the cell properties
          with the ones in the inflection.
          We do not check for suffix match because paradigm tables show example of a different word,
          not the one selected by the user.
           */
      if (cell.role === 'data') {
        return 'infl-prdgm-tbl-cell--data'
      }
    },

    navigate () {
      this.$emit('navigate', 'top')
    }
  }
}
