var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "alpheios-wordlist-commands" },
        [
          _c("div", { staticClass: "alpheios-wordlist-language__title" }, [
            _vm._v(_vm._s(_vm.languageName))
          ]),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                tooltipDirection: "top-left",
                tooltipText: _vm.l10n.getText("WORDLIST_TOOLTIP_ALL_IMPORTANT")
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "alpheios-wordlist-commands__item alpheios-wordlist-commands__item-all-important",
                  on: {
                    click: function($event) {
                      return _vm.makeAllImportant()
                    }
                  }
                },
                [_c("check-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                tooltipDirection: "top-left",
                tooltipText: _vm.l10n.getText("WORDLIST_TOOLTIP_NO_IMPORTANT")
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "alpheios-wordlist-commands__item alpheios-wordlist-commands__item-no-important",
                  on: {
                    click: function($event) {
                      return _vm.removeAllImportant()
                    }
                  }
                },
                [_c("check-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "alph-tooltip",
            {
              attrs: {
                tooltipDirection: "top-left",
                tooltipText: _vm.l10n.getText("WORDLIST_TOOLTIP_REMOVE_ALL")
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "alpheios-wordlist-commands__item alpheios-wordlist-commands__item-remove-all",
                  on: {
                    click: function($event) {
                      return _vm.showDeleteAll()
                    }
                  }
                },
                [_c("delete-icon")],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDeleteAllBox,
              expression: "showDeleteAllBox"
            }
          ],
          staticClass:
            "alpheios-wordlist-delete-all-confirmation alpheios-notification-area__notification alpheios-notification-area__notification--important"
        },
        [
          _c("div", { staticClass: "alpheios-notification-area__msg" }, [
            _vm._v(_vm._s(_vm.l10n.getText("WORDLIST_DELETE_CONFIRM_MESSAGE")))
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "alpheios-wordlist-delete-all-confirmation__buttons alpheios-notification-area__control"
            },
            [
              _c(
                "alph-tooltip",
                {
                  attrs: {
                    tooltipText: _vm.l10n.getText(
                      "WORDLIST_TOOLTIP_REMOVE_ALL"
                    ),
                    tooltipDirection: "bottom-wide"
                  }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "alpheios-button-primary",
                      on: {
                        click: function($event) {
                          return _vm.deleteAll()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.l10n.getText("WORDLIST_BUTTON_DELETE")) +
                          "\n          "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "alpheios-notification-area__close-btn",
              on: {
                click: function($event) {
                  return _vm.cancelDeleteAll()
                }
              }
            },
            [_c("close-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "alpheios-wordlist-filter-panel" },
        [
          _c("word-filter-panel", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasFilterPanel,
                expression: "hasFilterPanel"
              }
            ],
            attrs: {
              clickedLemma: _vm.clickedLemma,
              wordExactForms: _vm.wordExactForms,
              wordLemmaForms: _vm.wordLemmaForms,
              clearFilters: _vm.clearFilters
            },
            on: {
              changedFilterBy: _vm.changedFilterBy,
              clearClickedLemma: _vm.clearClickedLemma
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.wordItems, function(wordItem) {
        return _c(
          "div",
          { key: wordItem.targetWord },
          [
            _c("word-item", {
              attrs: { worditem: wordItem },
              on: {
                changeImportant: _vm.changeImportant,
                deleteItem: _vm.deleteItem,
                showContexts: _vm.showContexts,
                setLemmaFilterByClick: _vm.setLemmaFilterByClick
              }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }