var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.view.wideTable,
          expression: "view.wideTable"
        }
      ]
    },
    [
      _c(
        "div",
        {
          staticClass: "alpheios-inflections__title",
          on: { click: _vm.collapse }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.view.title) + "\n    "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.state.collapsed,
                  expression: "state.collapsed"
                }
              ]
            },
            [_vm._v("[+]")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.state.collapsed,
                  expression: "!state.collapsed"
                }
              ]
            },
            [_vm._v("[-]")]
          )
        ]
      ),
      _vm._v(" "),
      !_vm.state.collapsed
        ? [
            _c(
              "div",
              { staticClass: "infl-prdgm-tbl" },
              _vm._l(_vm.view.wideTable.rows, function(row) {
                return _c(
                  "div",
                  { staticClass: "infl-prdgm-tbl__row" },
                  _vm._l(row.cells, function(cell) {
                    return _c(
                      "div",
                      {
                        staticClass: "infl-prdgm-tbl__cell",
                        class: _vm.cellClasses(cell)
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(cell.value) + "\n        "
                        )
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }