var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "alpheios-wordlist-commands" },
        [
          _c(
            "alph-tooltip",
            {
              attrs: {
                tooltipDirection: "top-left",
                tooltipText: _vm.l10n.getText("WORDLIST_TOOLTIP_BACK")
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "alpheios-wordlist-commands__item alpheios-wordlist-commands__item-back",
                  on: { click: _vm.backToWordList }
                },
                [_c("back-icon")],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "alpheios-wordlist-language__title" }, [
            _vm._v(
              _vm._s(_vm.worditem.targetWord) +
                " (" +
                _vm._s(_vm.worditem.languageCode) +
                ")"
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.sourcesList, function(source) {
        return _c(
          "div",
          { staticClass: "alpheios-wordlists-tqs" },
          [
            _c("word-tq-source", {
              attrs: {
                source: source,
                tqSelectors: _vm.formattedContext[source]
              }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }