//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import interact from 'interactjs'

import Tooltip from '@/vue/components/tooltip.vue'
// Embeddable SVG icons
import InflectionsBrowserIcon from '@/images/inline-icons/inflections-browser.svg'
import StatusIcon from '@/images/inline-icons/status.svg'
import UserIcon from '@/images/inline-icons/user.svg'
import OptionsIcon from '@/images/inline-icons/options.svg'
import GrammarIcon from '@/images/inline-icons/resources.svg'
// import InfoIcon from '@/images/inline-icons/info.svg'
import HelpIcon from '@/images/inline-icons/help-icon.svg'
import WordlistIcon from '@/images/inline-icons/wordlist-icon.svg'
import CollapsedIcon from '@/images/inline-icons/collapsed.svg'
import ExpandedIcon from '@/images/inline-icons/expanded.svg'
import ReadingToolsIcon from '@/images/inline-icons/reading-tools.svg'
import LookupIcon from '@/images/inline-icons/lookup.svg'
// Vue components
import ToolbarCompact from '@/vue/components/nav/toolbar-compact.vue'
import Lookup from '@/vue/components/lookup.vue'
// Modules support
import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'Toolbar',
  extends: ToolbarCompact,
  // API modules that are required for this component
  inject: {
    app: 'app',
    ui: 'ui',
    l10n: 'l10n',
    settings: 'settings'
  },
  storeModules: ['toolbar', 'app', 'ui'], // Store modules that are required by this component
  mixins: [DependencyCheck],
  components: {
    lookup: Lookup,
    alphTooltip: Tooltip,
    inflectionsBrowserIcon: InflectionsBrowserIcon,
    statusIcon: StatusIcon,
    userIcon: UserIcon,
    optionsIcon: OptionsIcon,
    helpIcon: HelpIcon,
    grammarIcon: GrammarIcon,
    wordlistIcon: WordlistIcon,
    collapsedIcon: CollapsedIcon,
    expandedIcon: ExpandedIcon,
    readingToolsIcon: ReadingToolsIcon,
    lookupIcon: LookupIcon
  },
  interactInstance: null,
  dragTreshold: 100, // Drag distance values above this will be considered abnormal
  // Whether there is an error with Interact.js drag coordinates in the corresponding direction
  dragErrorX: false,
  dragErrorY: false,
  visibleUnwatch: null,

  data: function () {
    return {
      lookupVisible: false,
      contentVisible: false,

      // How much a toolbar has been dragged from its initial position, in pixels
      shift: {
        x: 0,
        y: 0
      },

      // An X position of the central point of a toolbar
      xCenter: undefined
    }
  },

  created () {
    // This is the earliest moment when data props are available
    this.shift.x = this.moduleConfig.initialShift.x
    this.shift.y = this.moduleConfig.initialShift.y
  },

  computed: {
    componentStyles: function () {
      let styles = {
        transform: `translate(${this.shift.x}px, ${this.shift.y}px)`,
        zIndex: this.ui.zIndex
      }

      if (this.moduleConfig) {
        if (this.moduleConfig.initialPos.top) {
          styles.top = this.moduleConfig.initialPos.top
        }
        if (this.moduleConfig.initialPos.right) {
          styles.right = this.moduleConfig.initialPos.right
        }
        if (this.moduleConfig.initialPos.bottom) {
          styles.bottom = this.moduleConfig.initialPos.bottom
        }
        if (this.moduleConfig.initialPos.left) {
          styles.left = this.moduleConfig.initialPos.left
        }
      }
      return styles
    },

    isInLeftHalf: function () {
      if (this.xCenter) {
        return (window.innerWidth / 2 - this.xCenter > 0)
      }
      // Default value is false as the toolbar's default position is at the right
      return false
    },

    componentClasses: function () {
      return this.isInLeftHalf ? 'alpheios-toolbar--left' : 'alpheios-toolbar--right'
    },

    tooltipDirection: function () {
      return this.isInLeftHalf ? 'right' : 'left'
    }
  },

  methods: {
    dragMoveListener (event) {
      let dx = event.dx
      let dy = event.dy
      /*
          On some websites Interact.js is unable to determine correct clientX or clientY coordinates.
          This will result in a popup moving abruptly beyond screen limits.
          To fix this, we will filter out erroneous coordinates and chancel a move in the corresponding
          direction as incorrect. This will allow us to keep the popup on screen by sacrificing its movement
          in (usually) one direction. This is probably the best we can do with all the information we have.
           */
      if (Math.abs(dx) > this.$options.dragTreshold) {
        if (!this.$options.dragErrorX) {
          console.warn(`Calculated horizontal drag distance is out of bounds: ${dx}. This is probably an error. Dragging in horizontal direction will be disabled.`)
          this.$options.dragErrorX = true
        }
        dx = 0
      }
      if (Math.abs(dy) > this.$options.dragTreshold) {
        if (!this.$options.dragErrorY) {
          console.warn(`Calculated vertical drag distance is out of bounds: ${dy}. This is probably an error. Dragging in vertical direction will be disabled.`)
          this.$options.dragErrorY = true
        }
        dy = 0
      }
      this.shift.x += dx
      this.shift.y += dy
    },

    dragEndListener () {
      this.settings.uiOptions.items.toolbarShiftX.setValue(this.shift.x)
      this.settings.uiOptions.items.toolbarShiftY.setValue(this.shift.y)
      // Recalculate the new position of a toolbar center
      this.xCenter = this.getXCenter()
    },

    isWithinViewport () {
      const rect = this.$el.getBoundingClientRect()
      return (
        rect.x >= 0 && (rect.x + rect.width) <= this.app.platform.viewport.width &&
        rect.y >= 0 && (rect.y + rect.height) <= this.app.platform.viewport.height
      )
    },

    /**
     * Return a x-coordinate of a central position of the toolbar
     * @return {number}
     */
    getXCenter () {
      const rect = this.$el.getBoundingClientRect()
      return rect.x + rect.width / 2
    }
  },

  mounted: function () {
    // Calculate an initial position of the central point
    this.xCenter = this.getXCenter()

    this.$options.visibleUnwatch = this.$store.watch((state) => state.toolbar.visible, (value) => {
      if (value) {
        // Check if the viewport is within the bounds of the viewport
        if (!this.isWithinViewport()) {
          // Reset the toolbar to its default position
          this.shift.x = 0
          this.shift.y = 0
          console.warn(`Toolbar has been reset to its default position to stay within the viewport`)
        }
      }
    })

    this.$options.interactInstance = interact(this.$el.querySelector('#alpheios-toolbar-drag-handle'))
      .draggable({
        inertia: true,
        autoScroll: false,
        modifiers: [
          interact.modifiers.restrict({
            restriction: {
              x: 27,
              y: 22,
              width: this.app.platform.viewport.width - 54,
              height: this.app.platform.viewport.height - 80
            },
            endOnly: true
          })
        ],
        ignoreFrom: 'input, textarea, a[href], select, option'
      })
      .on('dragmove', this.dragMoveListener)
      .on('dragend', this.dragEndListener)
  },

  beforeDestroy () {
    this.visibleUnwatch()
  }
}
