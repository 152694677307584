var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alpheios-grammar" }, [
    _c(
      "div",
      { staticClass: "alpheios-grammar__button--back-block" },
      [
        _c(
          "alph-tooltip",
          {
            attrs: {
              tooltipText: _vm.l10n.getText("TOOLTIP_BACK_TO_INDEX"),
              tooltipDirection: "bottom-left"
            }
          },
          [
            _c(
              "button",
              {
                staticClass: "alpheios-button-primary alpheios-svg-index",
                on: { click: _vm.returnToIndex }
              },
              [_c("back-icon")],
              1
            )
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    this.hasGrammarResUrl
      ? _c("div", { staticClass: "alpheios-grammar__frame-cont" }, [
          _c("iframe", {
            staticClass: "alpheios-grammar__frame",
            attrs: {
              src: _vm.$store.state.app.grammarRes.url,
              scrolling: "yes"
            }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.hasGrammarProvider,
            expression: "this.hasGrammarProvider"
          }
        ],
        staticClass: "alpheios-grammar__provider"
      },
      [_vm._v(_vm._s(_vm.grammarProvider))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }