var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataModel &&
    Object.keys(_vm.dataModel).length > 0 &&
    !_vm.dataModel.hidden
    ? _c(
        "div",
        { class: _vm.classes },
        [
          _c(
            "label",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTitle,
                  expression: "showTitle"
                }
              ],
              staticClass: "alpheios-setting__label"
            },
            [_vm._v(_vm._s(_vm.dataModel.labelText))]
          ),
          _vm._v(" "),
          _vm.dataModel.multiValue
            ? _c("multiselect", {
                staticClass: "alpheios-setting__control",
                attrs: {
                  "clear-on-select": false,
                  "close-on-select": true,
                  "hide-selected": true,
                  multiple: true,
                  options: _vm.values,
                  "preserve-search": true,
                  searchable: false,
                  placeholder: "Pick some"
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.dataModel.number
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selected,
                    expression: "selected"
                  }
                ],
                staticClass: "alpheios-input alpheios-setting__control",
                attrs: {
                  type: "number",
                  min: _vm.dataModel.minValue,
                  max: _vm.dataModel.maxValue
                },
                domProps: { value: _vm.selected },
                on: {
                  change: _vm.checkNumberField,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.selected = $event.target.value
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.dataModel.boolean
            ? _c(
                "div",
                {
                  staticClass:
                    "alpheios-checkbox-block alpheios-setting__control"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selected,
                        expression: "selected"
                      }
                    ],
                    attrs: { id: "alpheios-checkbox-input", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selected)
                        ? _vm._i(_vm.selected, null) > -1
                        : _vm.selected
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.selected,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.selected = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.selected = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      attrs: { for: "checkbox" },
                      on: { click: _vm.checkboxClick }
                    },
                    [_vm._v(_vm._s(_vm.checkboxLabel))]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.dataModel.multiValue &&
          !_vm.dataModel.boolean &&
          !_vm.dataModel.number
            ? _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selected,
                      expression: "selected"
                    }
                  ],
                  staticClass: "alpheios-select alpheios-setting__control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selected = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.values, function(item) {
                  return _c("option", [_vm._v(_vm._s(item))])
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }