//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import uuidv4 from 'uuid/v4'
import interact from 'interactjs'
import Vue from '@vue-runtime'

// Modules support
import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'InflFootnote',
  // API modules that are required for this component
  inject: {
    app: 'app'
  },
  storeModules: ['panel'], // Store modules that are required by this component
  mixins: [DependencyCheck],

  visibleUnwatch: null,

  props: {
    footnotes: {
      type: Array,
      required: true
    }
  },
  // An instance of interact.js
  interactInstance: undefined,

  data () {
    return {
      id: uuidv4(),
      target: null,
      footnotesPopupVisible: false,
      draggable: false,
      popupAlignmentStyles: { transform: undefined },
      inflpopup: null,
      inflpanel: null,
      defaultRightPadding: 10,
      defaultLeftPadding: 20
    }
  },
  mounted () {
    this.inflpopup = this.$el.querySelector('.alpheios-inflections__footnote-popup')
    this.inflpanel = this.$el.closest('#alpheios-panel__inflections-panel')

    if (this.app.platform.isMobile) {
      this.$options.visibleUnwatch = this.$store.watch((state) => state.panel.visibleFootnoteId, (id) => {
        if (this.footnotesPopupVisible && id !== this.id) {
          this.hidePopup()
        }
      })
    }
  },
  beforeDestroy () {
    this.$_alpheios_cleanup()
    if (this.$options.visibleUnwatch) {
      this.$options.visibleUnwatch()
    }
  },
  methods: {
    // Named according to Vue style guide: https://vuejs.org/v2/style-guide/#Private-property-names-essential
    $_alpheios_init () {
      if (this.draggable && !this.$options.interactInstance) {
        this.$options.interactInstance = interact(this.inflpopup)
          .draggable(this.draggableSettings())

        this.setTransformPopup('translate(-50%)')
      }
    },

    $_alpheios_cleanup () {
      if (this.$options.interactInstance) {
        this.$options.interactInstance.unset()
        this.$options.interactInstance = null
      }
    },

    setTransformPopup (transformValue) {
      this.popupAlignmentStyles.webkitTransform = transformValue
      this.popupAlignmentStyles.transform = transformValue
    },

    draggableSettings: function () {
      return {
        inertia: true,
        autoScroll: false,
        restrict: {
          restriction: '#alpheios-panel-inner',
          elementRect: { top: 0.5, left: 0.5, bottom: 0.5, right: 0.5 }
        },
        ignoreFrom: 'input, textarea, a[href], select, option',
        onmove: this.dragMoveListener
      }
    },

    dragMoveListener (event) {
      const target = event.target
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

      this.setTransformPopup(`translate(${x}px, ${y}px)`)

      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)
    },

    isOutOfRightXBound (childBR, parentBR) {
      return (childBR.x + childBR.width) > (parentBR.x + parentBR.width)
    },

    isOutOfLeftXBound (childBR, parentBR) {
      return childBR.x < 0
    },

    deltaRightXBound (childBR, parentBR) {
      return this.isOutOfRightXBound(childBR, parentBR)
        ? Math.round((childBR.x + childBR.width) - (parentBR.x + parentBR.width)) + this.defaultRightPadding : 0
    },

    deltaLeftXBound (childBR, parentBR) {
      return this.isOutOfLeftXBound(childBR, parentBR)
        ? Math.round(Math.abs(childBR.x)) - this.defaultLeftPadding : 0
    },

    checkBounds () {
      let popupBR = this.inflpopup.getBoundingClientRect()
      let panelBR = this.inflpanel.getBoundingClientRect()

      if (this.isOutOfRightXBound(popupBR, panelBR)) {
        this.setTransformPopup(`translateX(calc(-50% - ${this.deltaRightXBound(popupBR, panelBR)}px))`)
      } else if (this.isOutOfLeftXBound(popupBR, panelBR)) {
        this.setTransformPopup(`translateX(-${this.deltaLeftXBound(popupBR, panelBR)}px)`)
      }
    },

    showPopup () {
      if (this.app.platform.isDesktop) {
        this.draggable = true
        this.$_alpheios_init()
        Vue.nextTick().then(() => this.checkBounds())
      }
      this.footnotesPopupVisible = true
      this.$store.commit('panel/setVisibleFootnote', this.id)
    },

    hidePopup () {
      this.footnotesPopupVisible = false
      this.$_alpheios_cleanup()
      this.popupAlignmentStyles.transform = undefined
    }
  }
}

