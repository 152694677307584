//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tooltip from '@/vue/components/tooltip.vue'
import CheckIcon from '@/images/inline-icons/check.svg'
import DeleteIcon from '@/images/inline-icons/delete.svg'
import CloseIcon from '@/images/inline-icons/x-close.svg'
import WordItemPanel from '@/vue/components/word-list/word-item-panel.vue'
import WordFilterPanel from '@/vue/components/word-list/word-filter-panel.vue'

export default {
  name: 'WordLanguagePanel',
  components: {
    closeIcon: CloseIcon,
    checkIcon: CheckIcon,
    deleteIcon: DeleteIcon,
    wordItem: WordItemPanel,
    wordFilterPanel: WordFilterPanel,
    alphTooltip: Tooltip
  },
  inject: ['l10n', 'app'],
  props: {
    languageCode: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      reloadList: 1,
      showDeleteAllBox: false,
      selectedFilterBy: null,
      textInput: null,
      clickedLemma: null,
      filterMethods: {
        'byCurrentSession': (wordItem) => wordItem.currentSession,
        'byImportant': (wordItem) => wordItem.important,
        'byExactForm': (wordItem) => wordItem.targetWord.toLowerCase() === this.textInput.toLowerCase(),
        'byLemma': (wordItem) => wordItem.lemmasList.split(', ').some(lemmaItem => lemmaItem.toLowerCase() === this.textInput.toLowerCase())
      }, 
      clearFilters: 0
    }
  },
  computed: {
    hasFilterPanel () {
      return this.wordlist && this.wordlist.values && this.wordlist.values.length > 1
    },
    wordlist () {
      this.clearFilters = this.clearFilters + 1
      this.changedFilterBy(null, null)
      return this.$store.state.app.wordListUpdateTime && this.reloadList ? this.app.getWordList(this.languageCode) : { items: {} }
    },
    wordItems () {
      if (this.$store.state.app.wordListUpdateTime && this.reloadList) {        
        if (!this.selectedFilterBy) {
          return this.wordlist.values
        }
        if (this.filterMethods[this.selectedFilterBy]) {
          return this.wordlist.values.filter(this.filterMethods[this.selectedFilterBy])
        } else {
          console.warn(`The current filter method - ${this.selectedFilterBy} - is not defined, that's why empty result is returned!`)
        }
      }      
      return []
    },
    wordExactForms () {
      let exactForms = this.wordlist.values.reduce((acc, wordItem) => {
          let exactForm = wordItem.targetWord.toLowerCase()
          if (!acc.includes(exactForm)) {
            acc.push(exactForm)
        }
        return acc
      }, [])
      return exactForms.sort()
    },
    wordLemmaForms () {
      let lemmaForms = this.wordlist.values.reduce((acc, wordItem) => {
        let currentLemmaForms = wordItem.lemmasList.split(', ')
        currentLemmaForms.forEach(lemmaForm => {
          if (!acc.includes(lemmaForm)) {
            acc.push(lemmaForm)
          }
        })
        return acc
      }, [])
      return lemmaForms.sort()
    },
    languageName () {
      // TODO with upcoming merge, this can be retrived from utility library
      // so just return the code for now
      return this.languageCode
    }
  },
  methods: {
    showDeleteAll () {
      this.showDeleteAllBox = true
    },
    async makeAllImportant () {
      await this.app.updateAllImportant(this.languageCode, true)
      this.$emit('eventChangeImportant')
    },
    async removeAllImportant () {
      await this.app.updateAllImportant(this.languageCode, false)
      this.$emit('eventChangeImportant')
    },
    async changeImportant (targetWord, important) {
      await this.app.updateWordItemImportant(this.languageCode, targetWord, important)
    },
    async deleteItem (targetWord) {
      await this.app.removeWordListItem(this.languageCode, targetWord)
      this.reloadList = this.reloadList + 1
    },
    async deleteAll () {
      await this.app.removeWordList(this.languageCode)
      this.reloadList = this.reloadList + 1
      this.showDeleteAllBox = false
    },
    cancelDeleteAll () {
      this.showDeleteAllBox = false
    },
    showContexts (targetWord) {
      this.$emit('showContexts', targetWord, this.languageCode)
    },
    changedFilterBy (selectedFilterBy, textInput) {
      this.selectedFilterBy = selectedFilterBy
      this.textInput = textInput
    },
    setLemmaFilterByClick (lemma) {
      this.clickedLemma = lemma
    },
    clearClickedLemma () {
      this.clickedLemma = null
    }
  }
}
