var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closePanel,
          expression: "closePanel"
        },
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$store.state.panel.visible,
          expression: "$store.state.panel.visible"
        }
      ],
      staticClass: "alpheios-panel alpheios-panel--large alpheios-content",
      class: _vm.rootClasses,
      style: _vm.componentStyles,
      attrs: {
        "data-component": "alpheios-panel",
        "data-resizable": "true",
        "data-resized": _vm.resized,
        id: "alpheios-panel-inner"
      }
    },
    [
      _c("div", { staticClass: "alpheios-panel__header" }, [
        _c(
          "div",
          { staticClass: "alpheios-panel__header-logo" },
          [_c("logo-icon", { staticClass: "alpheios-logo-on-dark" })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "alpheios-panel__header-btn-group--center" },
          [
            _c("navbuttons-large"),
            _vm._v(" "),
            _c(
              "alph-tooltip",
              {
                attrs: {
                  tooltipText: _vm.swapTooltip,
                  tooltipDirection: "bottom-narrow"
                }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "alpheios-navbuttons__btn",
                    on: {
                      click: function($event) {
                        return _vm.swapPosition()
                      }
                    }
                  },
                  [_c("swap-position")],
                  1
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "alpheios-panel__header-btn-group--end" }, [
          _c(
            "div",
            {
              staticClass: "alpheios-panel__close-btn",
              on: { click: _vm.ui.closePanel }
            },
            [_c("close-icon")],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "alpheios-panel__content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("definitions"),
                expression: "$store.getters['ui/isActiveTab']('definitions')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__definitions",
            attrs: { "data-alpheios-ignore": "all" }
          },
          [
            _c(
              "div",
              { staticClass: "alpheios-lookup__panel" },
              [_c("lookup", { attrs: { "name-base": "panel-defs" } })],
              1
            ),
            _vm._v(" "),
            _vm.$store.getters["app/defDataReady"]
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.formattedShortDefinitions, function(definition) {
                      return _c(
                        "div",
                        {
                          key: definition.ID,
                          staticClass: "alpheios-panel__contentitem"
                        },
                        [
                          _c("shortdef", {
                            attrs: {
                              definition: definition,
                              languageCode: _vm.$store.state.app.languageCode
                            }
                          })
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "alpheios-panel__contentitem alpheios-panel__contentitem-full-definitions",
                      domProps: {
                        innerHTML: _vm._s(_vm.formattedFullDefinitions)
                      }
                    })
                  ],
                  2
                )
              : _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.l10n.getText("PLACEHOLDER_DEFINITIONS")) +
                      "\n      "
                  )
                ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.$store.state.app.hasInflData &&
                  _vm.$store.getters["ui/isActiveTab"]("inflections"),
                expression:
                  "$store.state.app.hasInflData && $store.getters['ui/isActiveTab']('inflections')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__inflections",
            attrs: { id: _vm.inflectionsPanelID, "data-alpheios-ignore": "all" }
          },
          [
            _c("h1", { staticClass: "alpheios-panel__title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.l10n.getText("TITLE_INFLECTIONS_PANEL")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("inflections", { staticClass: "alpheios-panel-inflections" })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"](
                  "inflectionsbrowser"
                ),
                expression:
                  "$store.getters['ui/isActiveTab']('inflectionsbrowser')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__inflectionsbrowser",
            attrs: {
              id: _vm.inflectionsBrowserPanelID,
              "data-alpheios-ignore": "all"
            }
          },
          [
            _c("h1", { staticClass: "alpheios-panel__title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.l10n.getText("TITLE_INFLECTIONS_BROWSER_PANEL")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("inflection-browser")
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("grammar"),
                expression: "$store.getters['ui/isActiveTab']('grammar')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__grammar\n          alpheios-panel__tab-panel--no-padding",
            attrs: { "data-alpheios-ignore": "all" }
          },
          [_c("grammar")],
          1
        ),
        _vm._v(" "),
        _vm.$store.getters["app/hasTreebankData"]
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$store.getters["ui/isActiveTab"]("treebank"),
                    expression: "$store.getters['ui/isActiveTab']('treebank')"
                  }
                ],
                staticClass:
                  "alpheios-panel__tab-panel alpheios-panel__tab__treebank",
                attrs: { "data-alpheios-ignore": "all" }
              },
              [_c("treebank")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("status"),
                expression: "$store.getters['ui/isActiveTab']('status')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__status",
            attrs: { "data-alpheios-ignore": "all" }
          },
          _vm._l(_vm.$store.state.ui.messages, function(message) {
            return _c("div", [
              _c("div", { staticClass: "alpheios-panel__message" }, [
                _vm._v(_vm._s(message))
              ])
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm.$store.state.auth.enableLogin
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$store.getters["ui/isActiveTab"]("user"),
                    expression: "$store.getters['ui/isActiveTab']('user')"
                  }
                ],
                staticClass:
                  "alpheios-panel__tab-panel alpheios-panel__tab__user",
                attrs: { "data-alpheios-ignore": "all" }
              },
              [_c("user-auth")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("wordUsage"),
                expression: "$store.getters['ui/isActiveTab']('wordUsage')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab-panel--scroll"
          },
          [_c("word-usage-examples")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("options"),
                expression: "$store.getters['ui/isActiveTab']('options')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab-panel--options",
            attrs: { "data-alpheios-ignore": "all" }
          },
          [
            _c("ui-settings"),
            _vm._v(" "),
            _c("feature-settings"),
            _vm._v(" "),
            _c("resource-settings"),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "alpheios-button-primary",
                  on: { click: _vm.resetAllOptions }
                },
                [
                  _vm._v(
                    _vm._s(_vm.l10n.getText("LABEL_RESET_OPTIONS")) +
                      "\n        "
                  )
                ]
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("info"),
                expression: "$store.getters['ui/isActiveTab']('info')"
              }
            ],
            staticClass: "alpheios-panel__tab-panel alpheios-panel__tab__info",
            attrs: { "data-alpheios-ignore": "all" }
          },
          [
            _c("h1", { staticClass: "alpheios-panel__title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.l10n.getText("TITLE_HELP_PANEL")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "alpheios-lookup__panel" },
              [_c("lookup", { attrs: { "name-base": "panel-info" } })],
              1
            ),
            _vm._v(" "),
            _c("info")
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters["ui/isActiveTab"]("wordlist"),
                expression: "$store.getters['ui/isActiveTab']('wordlist')"
              }
            ],
            staticClass:
              "alpheios-panel__tab-panel alpheios-panel__tab__wordlist",
            attrs: { "data-alpheios-ignore": "all" }
          },
          [_c("word-list-panel")],
          1
        )
      ]),
      _vm._v(" "),
      _c("notification-area")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }