var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alpheios-wordlists-tq" },
    [
      _c(
        "a",
        {
          staticClass: "alpheios-wordlists-tq-title",
          attrs: { href: _vm.source, target: "_blank" }
        },
        [_vm._v(_vm._s(_vm.source))]
      ),
      _vm._v(" "),
      _vm._l(_vm.tqSelectors, function(tq) {
        return _c(
          "div",
          {
            key: tq.ID,
            staticClass: "alpheios-wordlists-tq-contextHTML-block"
          },
          [
            _c("p", {
              staticClass: "alpheios-wordlists-tq-contextHTML",
              domProps: { innerHTML: _vm._s(tq.contextHTML) }
            })
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }