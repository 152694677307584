var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alpheios-navbuttons alpheios-navbuttons--large" },
    [
      _c(
        "alph-tooltip",
        {
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_HELP"),
            tooltipDirection: "bottom-narrow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: { active: _vm.$store.getters["ui/isActiveTab"]("info") },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("info")
                }
              }
            },
            [_c("help-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$store.getters["app/defDataReady"],
              expression: "$store.getters['app/defDataReady']"
            }
          ],
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_SHOW_DEFINITIONS"),
            tooltipDirection: "bottom-narrow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: {
                active: _vm.$store.getters["ui/isActiveTab"]("definitions")
              },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("definitions")
                }
              }
            },
            [_c("definitions-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$store.state.app.hasInflData,
              expression: "$store.state.app.hasInflData"
            }
          ],
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_SHOW_INFLECTIONS"),
            tooltipDirection: "bottom-narrow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: {
                active: _vm.$store.getters["ui/isActiveTab"]("inflections")
              },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("inflections")
                }
              }
            },
            [_c("inflections-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$store.getters["app/hasTreebankData"],
              expression: "$store.getters['app/hasTreebankData']"
            }
          ],
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_TREEBANK"),
            tooltipDirection: "bottom-narrow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: {
                active: _vm.$store.getters["ui/isActiveTab"]("treebank")
              },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("treebank")
                }
              }
            },
            [_c("treebank-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$store.state.app.wordUsageExampleEnabled,
              expression: "$store.state.app.wordUsageExampleEnabled"
            }
          ],
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_SHOW_USAGEEXAMPLES"),
            tooltipDirection: "bottom-narrow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: {
                active: _vm.$store.getters["ui/isActiveTab"]("wordUsage")
              },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("wordUsage")
                }
              }
            },
            [_c("word-usage-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_INFLECT_BROWSER"),
            tooltipDirection: "bottom-narrow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: {
                active: _vm.$store.getters["ui/isActiveTab"](
                  "inflectionsbrowser"
                )
              },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("inflectionsbrowser")
                }
              }
            },
            [_c("inflections-browser-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$store.getters["app/hasGrammarRes"],
              expression: "$store.getters[`app/hasGrammarRes`]"
            }
          ],
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_GRAMMAR"),
            tooltipDirection: "bottom-narrow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: {
                active: _vm.$store.getters["ui/isActiveTab"]("grammar")
              },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("grammar")
                }
              }
            },
            [_c("grammar-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_WORDLIST"),
            tooltipDirection: "bottom-narrow"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.$store.state.app.hasWordListsData,
                  expression: "this.$store.state.app.hasWordListsData"
                }
              ],
              staticClass: "alpheios-navbuttons__btn",
              class: {
                active: _vm.$store.getters["ui/isActiveTab"]("wordlist")
              },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("wordlist")
                }
              }
            },
            [_c("wordlist-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$store.state.auth.enableLogin,
              expression: "$store.state.auth.enableLogin"
            }
          ],
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_USER"),
            tooltipDirection: "bottom-narrow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: { active: _vm.$store.getters["ui/isActiveTab"]("user") },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("user")
                }
              }
            },
            [_c("user-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_OPTIONS"),
            tooltipDirection: "bottom-narrow"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "alpheios-navbuttons__btn",
              class: {
                active: _vm.$store.getters["ui/isActiveTab"]("options")
              },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("options")
                }
              }
            },
            [_c("options-icon")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "alph-tooltip",
        {
          attrs: {
            tooltipText: _vm.l10n.getText("TOOLTIP_STATUS"),
            tooltipDirection: "bottom-narrow"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    this.settings.uiOptions.items.verboseMode.currentValue ===
                    "verbose",
                  expression:
                    "this.settings.uiOptions.items.verboseMode.currentValue === `verbose`"
                }
              ],
              staticClass: "alpheios-navbuttons__btn",
              class: { active: _vm.$store.getters["ui/isActiveTab"]("status") },
              on: {
                click: function($event) {
                  return _vm.ui.changeTab("status")
                }
              }
            },
            [_c("status-icon")],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }