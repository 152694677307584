var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: this.$store.state.auth.enableLogin,
          expression: "this.$store.state.auth.enableLogin"
        }
      ]
    },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !this.$store.state.auth.isAuthenticated &&
                !this.$store.state.auth.externalLoginUrl,
              expression:
                "! this.$store.state.auth.isAuthenticated && ! this.$store.state.auth.externalLoginUrl"
            }
          ],
          class: _vm.btnClass,
          on: { click: _vm.logIn }
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.l10n.getMsg("AUTH_LOGIN_BTN_LABEL")) + "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !this.$store.state.auth.isAuthenticated &&
                this.$store.state.auth.externalLoginUrl,
              expression:
                "! this.$store.state.auth.isAuthenticated && this.$store.state.auth.externalLoginUrl"
            }
          ],
          attrs: { href: _vm.loginLink }
        },
        [
          _c("button", { class: _vm.btnClass }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.l10n.getMsg("AUTH_LOGIN_BTN_LABEL")) +
                "\n    "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                this.$store.state.auth.isAuthenticated &&
                !this.$store.state.auth.externalLogoutUrl,
              expression:
                "this.$store.state.auth.isAuthenticated && !this.$store.state.auth.externalLogoutUrl"
            }
          ],
          class: _vm.btnClass,
          on: { click: _vm.logOut }
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.l10n.getMsg("AUTH_LOGOUT_BTN_LABEL")) + "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                this.$store.state.auth.isAuthenticated &&
                this.$store.state.auth.externalLogoutUrl,
              expression:
                "this.$store.state.auth.isAuthenticated && this.$store.state.auth.externalLogoutUrl"
            }
          ],
          attrs: { href: _vm.logoutLink }
        },
        [
          _c("button", { class: _vm.btnClass }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.l10n.getMsg("AUTH_LOGOUT_BTN_LABEL")) +
                "\n    "
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }