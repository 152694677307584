//
//
//
//
//
//
//
//
//
//
//

// Embeddable SVG icons
import ToolbarIcon from '@/images/inline-icons/reading-tools.svg'

// Modules support
import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'Toolbar',
  // API modules that are required for this component
  inject: {
    app: 'app',
    ui: 'ui',
    l10n: 'l10n'
  },
  storeModules: ['toolbar', 'app', 'ui'], // Store modules that are required by this component
  mixins: [DependencyCheck],
  components: {
    toolbarIcon: ToolbarIcon
  },

  computed: {
    componentStyles: function () {
      return {
        zIndex: this.ui.zIndex
      }
    }
  }
}
