var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alpheios-definition__short" }, [
    _c(
      "span",
      {
        staticClass: "alpheios-definition__lemma",
        attrs: { lang: _vm.languageCode }
      },
      [_vm._v(_vm._s(_vm.definition.lemmaText) + ":")]
    ),
    _vm._v(" "),
    _c("span", { staticClass: "alpheios-definition__text" }, [
      _vm._v(_vm._s(_vm.definition.text))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }