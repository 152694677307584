var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alpheios-inflections__forms-cont" }, [
    _c("span", { staticClass: "alpheios-inflections__forms-targetword" }, [
      _vm._v('"' + _vm._s(_vm.targetWord) + '"')
    ]),
    _vm._v(" "),
    _vm.forms && _vm.forms.length > 0
      ? _c(
          "span",
          { staticClass: "alpheios-inflections__form-parts" },
          [
            _c("span", [_vm._v("(")]),
            _vm._v(" "),
            _vm._l(_vm.forms, function(form, index) {
              return _c(
                "span",
                { staticClass: "alpheios-inflections__form-part" },
                [
                  _vm._v("\n          " + _vm._s(form)),
                  index < _vm.forms.length - 1
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e()
                ]
              )
            }),
            _vm._v(" "),
            _c("span", [_vm._v(")")])
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }