//
//
//
//
//
//
//
//
//
//
//
//
//

import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'
import BackIcon from '@/images/inline-icons/back.svg'
import Tooltip from './tooltip.vue'

export default {
  name: 'Grammar',
  inject: ['l10n', 'app'],
  storeModules: ['app'],
  mixins: [DependencyCheck],
  components: {
    backIcon: BackIcon,
    alphTooltip: Tooltip
  },
  computed: {
    hasGrammarResUrl: function () {
      return Boolean(this.$store.state.app.grammarRes && this.$store.state.app.grammarRes.url)
    },

    hasGrammarProvider: function () {
      return Boolean(this.$store.state.app.grammarRes && this.$store.state.app.grammarRes.provider)
    },

    grammarProvider: function () {
      return this.hasGrammarProvider ? this.$store.state.app.grammarRes.provider.toString() : ''
    }
  },
  methods: {
    returnToIndex () {
      this.app.restoreGrammarIndex()
    }
  }
}
