//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Definition, Feature, LanguageModelFactory } from 'alpheios-data-models'
import ShortDef from './shortdef.vue'
import InflectionAttribute from './infl-attribute.vue'
import LemmaTranslation from './lemma-translation.vue'
// Modules support
import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'Morph',
  components: {
    shortdef: ShortDef,
    inflectionattribute: InflectionAttribute,
    lemmatranslation: LemmaTranslation
  },
  inject: ['app', 'l10n'],
  storeModules: ['app'],
  mixins: [DependencyCheck],
  data: function () {
    return {
      types: null // These are Feature.types
    }
  },
  computed: {
    lexemes () {
      // A call to `defDataReady` will force this computed prop to recalculate every time definitions data is updated
      let defs = this.$store.getters['app/defDataReady']
      return this.$store.state.app.morphDataReady ? this.app.getHomonymLexemes() : []
    },

    translations () {
      let translations = {}
      if (this.$store.state.app.translationsDataReady) {
        for (let lexeme of this.lexemes) {
          if (lexeme.lemma.translation !== undefined) {
            translations[lexeme.lemma.ID] = lexeme.lemma.translation
          }
        }
      }
      return translations
    }
  },
  methods: {
    showLexeme (lex) {
      return (lex.isPopulated) ? lex.isPopulated() : false
    },

    groupClass (group) {
      return group.groupingKey.isCaseInflectionSet ? 'alpheios-morph__inline' : 'alpheios-morph__block'
    },

    featureMatch (a, b) {
      if (a && b) {
        return a.isEqual(b)
      }
      return false
    },

    getFeature (lemma, type) {
      if (lemma.features[type] !== undefined) {
        return lemma.features[type].value
      }
      return undefined
    },

    definitionIndex (index) {
      let letters = 'abcdefghijklmnopqrstuvwxyz'
      return letters.substr(index, 1) + '.'
    },

    featureList (lemma, features, name) {
      let list = features.map(i => lemma.features[i] ? lemma.features[i] : null).filter(i => i)
      list = list.length > 0 ? `(${list.map((f) => f).join(', ')})` : ''
      let returnObj = {}
      returnObj[name] = { value: list, values: [list] }
      return returnObj
    },

    languageCode (languageID) {
      return LanguageModelFactory.getLanguageCodeFromId(languageID)
    },

    allLemmas (lex) {
      if (lex.altLemmas && lex.altLemmas.length > 0) {
        return [lex.lemma, ...lex.altLemmas].sort((a, b) => {
          if (a.features[Feature.types.frequency]) {
            return a.features[Feature.types.frequency].compareTo(b.features[Feature.types.frequency])
          } else if (b.features[Feature.types.frequency]) {
            // frequency of a isn't defined so sort b first
            return 1
          } else {
            // equal
            return 0
          }
        })
      } else {
        return [lex.lemma]
      }
    },

    morphClass (lex) {
      let c = 'alpheios-morph__dictentry'
      if (lex.disambiguated) {
        c = `${c} alpheios-morph__dictentry-disambiguated`
      }
      return c
    },

    inflections (lex) {
      return (
        this.$store.state.app.morphDataReady && this.app.hasMorphData() && lex.getGroupedInflections)
        ? lex.getGroupedInflections()
        : []
    },

    definitions (lex) {
      let definitions = []
      if (lex.meaning && lex.meaning.shortDefs && lex.meaning.shortDefs.length > 0) {
        definitions = lex.meaning.shortDefs
        // We don't need the deduplication code below as of now; it is here just for historic reference
        /* for (const def of lexeme.meaning.shortDefs) {
            // for now, to avoid duplicate showing of the provider we create a new unproxied definitions
            // object without a provider if it has the same provider as the morphology info
            if (def.provider && lexeme.provider && def.provider.uri === lexeme.provider.uri) {
              definitions.push(new Definition(def.text, def.language, def.format, def.lemmaText))
            } else {
              definitions.push(def)
            }
          } */
      } else if (lex.lemma.features && Object.entries(lex.lemma.features).length > 0) {
        definitions = [new Definition(this.l10n.getMsg('TEXT_NOTICE_NO_DEFS_FOUND'), 'en-US', 'text/plain', lex.lemma.word)]
      }
      return definitions
    }
  },
  created: function () {
    this.types = Feature.types
  }
}
