//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tooltip from '@/vue/components/tooltip.vue'
import ProgressBar from '@/vue/components/progress-bar.vue'
// Embeddable SVG icons
import InflectionsBrowserIcon from '@/images/inline-icons/inflections-browser.svg'
import UserIcon from '@/images/inline-icons/user.svg'
import OptionsIcon from '@/images/inline-icons/options.svg'
import GrammarIcon from '@/images/inline-icons/resources.svg'
import WordlistIcon from '@/images/inline-icons/wordlist-icon.svg'
import CloseIcon from '@/images/inline-icons/x-close.svg'
// Vue components
import Lookup from '@/vue/components/lookup.vue'
// Modules support
import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'

export default {
  name: 'ActionPanel',
  // API modules that are required for this component
  inject: {
    ui: 'ui',
    l10n: 'l10n'
  },
  storeModules: ['actionPanel', 'app'], // Store modules that are required by this component
  mixins: [DependencyCheck],
  components: {
    lookup: Lookup,
    alphTooltip: Tooltip,
    progressBar: ProgressBar,
    inflectionsBrowserIcon: InflectionsBrowserIcon,
    userIcon: UserIcon,
    optionsIcon: OptionsIcon,
    grammarIcon: GrammarIcon,
    wordlistIcon: WordlistIcon,
    closeIcon: CloseIcon
  },

  data: function () {
    return {
      lookupVisible: false,
      contentVisible: false,

      // How much an action panel has been dragged from its initial position, in pixels
      shift: {
        x: 0,
        y: 0
      },

      tooltipDirection: 'top'
    }
  },

  created () {
    // This is the earliest moment when data props are available
    this.shift.x = this.config.initialShift.x
    this.shift.y = this.config.initialShift.y
  },

  computed: {
    componentStyles: function () {
      let styles = {
        transform: `translate(${this.shift.x}px, ${this.shift.y}px)`,
        // Should stay on top of a toolbar
        zIndex: this.ui.zIndex + 10
      }

      if (this.$store.state.actionPanel.initialPos) {
        if (this.$store.state.actionPanel.initialPos.top) {
          styles.top = `${this.$store.state.actionPanel.initialPos.top}px`
        }
        if (this.$store.state.actionPanel.initialPos.right) {
          styles.right = `${this.$store.state.actionPanel.initialPos.right}px`
        }
        if (this.$store.state.actionPanel.initialPos.bottom) {
          styles.bottom = `${this.$store.state.actionPanel.initialPos.bottom}px`
        }
        if (this.$store.state.actionPanel.initialPos.left) {
          styles.left = `${this.$store.state.actionPanel.initialPos.left}px`
        }
      }
      return styles
    },

    showPanel: function () {
      // As modules are registered in an arbitrary order, panel module may not be available
      // during rendering of an action panel
      const panelVisible = this.$store.state.panel ? this.$store.state.panel.visible : false
      return this.$store.state.actionPanel.visible && !panelVisible
    },

    // Need this to return an object to Vue template when moduleConfig data is not available yet.
    config () {
      return this.moduleConfig || {}
    }
  },

  methods: {
    tooltipText (messageID, availabilityCondition = 'N/A') {
      if (availabilityCondition !== 'N/A') {
        return availabilityCondition
          ? this.l10n.getText(messageID)
          : `${this.l10n.getText(messageID)} (${this.l10n.getText('TOOLTIP_NOT_AVAIL_POSTFIX')})`
      }
      return this.l10n.getText(messageID)
    },

    openTab (tabName) {
      this.ui.showPanelTab(tabName)
      if (this.config.closeAfterNav) {
        this.$store.commit('actionPanel/close')
      }
    },

    // A callback for the `lookup-started` emitted by the Lookup component.
    lookupStarted () {
      if (this.config.closeAfterLookup) {
        this.$store.commit('actionPanel/close')
      }
    }
  }
}
