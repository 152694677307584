//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FontSize from './font-size.vue'
import Setting from './setting.vue'
import DependencyCheck from '@/vue/vuex-modules/support/dependency-check.js'
export default {
  name: 'UISettings',
  // API modules that are required for this component
  inject: {
    app: 'app',
    ui: 'ui',
    l10n: 'l10n',
    settings: 'settings'
  },
  storeModules: ['app','ui'], // Store modules that are required by this component
  mixins: [DependencyCheck],
  components: {
    setting: Setting,
    fontSize: FontSize
  },
  methods: {
    uiOptionChanged: function (name, value) {
      this.ui.optionChange(name, value)
    }
  }
}
