var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "alpheios-inflections__title alpheios-clickable",
        on: { click: _vm.collapse }
      },
      [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.state.collapsed,
                expression: "state.collapsed"
              }
            ]
          },
          [_vm._v("[+]")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.state.collapsed,
                expression: "!state.collapsed"
              }
            ]
          },
          [_vm._v("[-]")]
        )
      ]
    ),
    _vm._v(" "),
    _vm.hasInflectionTables &&
    _vm.state.view.canCollapse &&
    _vm.state.noSuffixGroupsHidden
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.state.collapsed,
                expression: "!state.collapsed"
              }
            ],
            staticClass: "alpheios-inflections__table-ctrl-cell--btn"
          },
          [
            _c(
              "alph-tooltip",
              {
                attrs: {
                  tooltipText: _vm.l10n.getMsg("TOOLTIP_INFLECT_SHOWFULL"),
                  tooltipDirection: "bottom-right"
                }
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "alpheios-button-secondary alpheios-inflections__control-btn alpheios-inflections__control-btn--right",
                    on: { click: _vm.showNoSuffixGroups }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.l10n.getMsg("LABEL_INFLECT_SHOWFULL")) +
                        "\n          "
                    )
                  ]
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasInflectionTables && !_vm.state.standardFormTable
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  !_vm.state.collapsed &&
                  _vm.state.view.canCollapse &&
                  !_vm.state.noSuffixGroupsHidden,
                expression:
                  "!state.collapsed && state.view.canCollapse && !state.noSuffixGroupsHidden"
              }
            ],
            staticClass: "alpheios-inflections__table-ctrl-cell--btn"
          },
          [
            _c(
              "alph-tooltip",
              {
                attrs: {
                  tooltipText: _vm.l10n.getMsg("TOOLTIP_INFLECT_COLLAPSE"),
                  tooltipDirection: "bottom-right"
                }
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "alpheios-button-secondary alpheios-inflections__control-btn alpheios-inflections__control-btn--right",
                    on: { click: _vm.hideNoSuffixGroups }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.l10n.getMsg("LABEL_INFLECT_COLLAPSE")) +
                        "\n        "
                    )
                  ]
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "h4",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.state.collapsed && _vm.additionalTitle,
            expression: "!state.collapsed && additionalTitle"
          }
        ],
        staticClass: "alpheios-inflections__additional_title"
      },
      [_vm._v("\n    " + _vm._s(_vm.additionalTitle) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.state.collapsed,
            expression: "!state.collapsed"
          }
        ],
        staticClass: "alpheios-inflections__wide-view"
      },
      [
        _vm.hasInflectionTables
          ? _c(
              "div",
              {
                staticClass: "infl-table infl-table--wide",
                style: _vm.tableStyles,
                attrs: { id: "alpheios-wide-vue-table" }
              },
              [
                _vm._l(_vm.state.view.wideView.rows, function(row) {
                  return _vm._l(row.cells, function(cell) {
                    return _c(
                      "div",
                      {
                        class: _vm.cellClasses(cell),
                        on: {
                          mouseleave: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.cellMouseLeave(cell)
                          },
                          mouseover: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.cellMouseOver(cell)
                          }
                        }
                      },
                      [
                        cell.isDataCell
                          ? [
                              _vm._l(cell.morphemes, function(morpheme, index) {
                                return [
                                  _c(
                                    "span",
                                    { class: _vm.morphemeClasses(morpheme) },
                                    [
                                      morpheme.value
                                        ? [_vm._v(_vm._s(morpheme.value))]
                                        : [_vm._v("-")]
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  morpheme.hasFootnotes
                                    ? _c("infl-footnote", {
                                        attrs: { footnotes: morpheme.footnotes }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  index < cell.morphemes.length - 1
                                    ? [_vm._v(",")]
                                    : _vm._e()
                                ]
                              })
                            ]
                          : _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.l10n.getText(cell.value))
                              }
                            })
                      ],
                      2
                    )
                  })
                })
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasPrerenderedTables
          ? _c(
              "div",
              { staticClass: "infl-prdgm-tbl" },
              _vm._l(_vm.state.view.wideTable.rows, function(row) {
                return _c(
                  "div",
                  { staticClass: "infl-prdgm-tbl__row" },
                  _vm._l(row.cells, function(cell) {
                    return _c(
                      "div",
                      {
                        staticClass: "infl-prdgm-tbl__cell",
                        class: _vm.prerenderedCellClasses(cell)
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(cell.value) + "\n        "
                        )
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.state.collapsed && !_vm.isAvailable,
            expression: "!state.collapsed && !isAvailable"
          }
        ],
        staticClass: "alpheios-inflections__not-impl-msg"
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.l10n.getMsg("INFLECT_MSG_TABLE_NOT_IMPLEMENTED")) +
            "\n  "
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }