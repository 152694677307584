//
//
//
//
//
//
//

export default {
  name: 'ShortDef',
  props: ['definition', 'languageCode']
}
