var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alph_tooltip" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.renderTooltip
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tooltipText,
                  expression: "tooltipText"
                }
              ],
              staticClass: "tooltiptext alpheios-text__smaller",
              class: _vm.directionClass,
              style: _vm.additionalStyles
            },
            [_vm._v("\n    " + _vm._s(_vm.tooltipText) + "\n  ")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }