var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alpheios-resource-options__cont" },
    [
      _vm._l(_vm.resourceSettingsLexicons, function(languageSetting) {
        return _c("setting", {
          key: languageSetting.name,
          attrs: {
            classes: ["alpheios-resource-options__item"],
            data: languageSetting
          },
          on: { change: _vm.resourceSettingChanged }
        })
      }),
      _vm._v(" "),
      _vm._l(_vm.resourceSettingsLexiconsShort, function(languageSetting) {
        return _c("setting", {
          key: languageSetting.name,
          attrs: {
            classes: ["alpheios-resource-options__item"],
            data: languageSetting
          },
          on: { change: _vm.resourceSettingChanged }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }