var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "alpheios-popup-lexdataloading__progress-wrapper" },
      [
        _c(
          "div",
          { staticClass: "alpheios-popup-lexdataloading__progress-inner" },
          [
            _c("div", {
              staticClass: "alpheios-popup-lexdataloading__progress-line"
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.text,
                    expression: "text"
                  }
                ],
                staticClass: "alpheios-popup-lexdataloading__progress-text"
              },
              [_vm._v("\n        " + _vm._s(_vm.text) + "\n      ")]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }