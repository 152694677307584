var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alpheios-lemma__translations" }, [
    _vm.translations &&
    _vm.translations[_vm.lemmakey] &&
    _vm.translations[_vm.lemmakey].glosses &&
    _vm.translations[_vm.lemmakey].glosses.length > 0
      ? _c(
          "div",
          { staticClass: "hasValue", attrs: { "data-lemmakey": _vm.lemmakey } },
          _vm._l(_vm.translations[_vm.lemmakey].glosses, function(
            gloss,
            gindex
          ) {
            return _c(
              "p",
              { staticClass: "alpheios-lemma__translations-value" },
              [
                _vm.translations[_vm.lemmakey].glosses.length > 1
                  ? _c("span", { staticClass: "translation_index" }, [
                      _vm._v("-")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "alpheios-lemma__translations-gloss" },
                  [_vm._v(_vm._s(gloss))]
                )
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }