var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alpheios-info" }, [
    _c(
      "div",
      { staticClass: "alpheios-info__versiontext alpheios-text__smallest" },
      [_vm._v(_vm._s(_vm.app.name) + " " + _vm._s(_vm.app.version) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "alpheios-info__currentlanguage alpheios-text__smallest" },
      [
        _vm._v(
          _vm._s(_vm.l10n.getMsg("LABEL_INFO_CURRENTLANGUAGE")) +
            "\n    " +
            _vm._s(_vm.$store.state.app.currentLanguageName) +
            "\n  "
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "alpheios-info__helptext" }, [
      _c("h3", [_vm._v(_vm._s(_vm.l10n.getMsg("TEXT_INFO_GETTINGSTARTED")))]),
      _vm._v(" "),
      _c("p", { staticClass: "alpheios-text__small" }, [
        _vm._v(_vm._s(_vm.l10n.getMsg("TEXT_INFO_ACTIVATE")))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "alpheios-text__small" }, [
        _vm._v(_vm._s(_vm.l10n.getMsg("TEXT_INFO_CLICK")))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "alpheios-text__small" }, [
        _vm._v(_vm._s(_vm.l10n.getMsg("TEXT_INFO_LANGDETECT")))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "alpheios-text__small" }, [
        _vm._v(_vm._s(_vm.l10n.getMsg("TEXT_INFO_SETTINGS")))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "alpheios-text__small" }, [
        _vm._v(_vm._s(_vm.l10n.getMsg("TEXT_INFO_ARROW")))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "alpheios-text__small" }, [
        _vm._v(_vm._s(_vm.l10n.getMsg("TEXT_INFO_REOPEN")))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "alpheios-text__small" }, [
        _vm._v(_vm._s(_vm.l10n.getMsg("TEXT_INFO_DEACTIVATE")))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }