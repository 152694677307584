var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alpheios-notification-area" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNotification,
            expression: "showNotification"
          }
        ],
        staticClass: "alpheios-notification-area__notification",
        class: _vm.notificationClasses
      },
      [
        _c("div", {
          staticClass: "alpheios-notification-area__msg",
          domProps: { innerHTML: _vm._s(_vm.$store.state.ui.notification.text) }
        }),
        _vm._v(" "),
        _c("setting", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$store.state.ui.notification.showLanguageSwitcher,
              expression: "$store.state.ui.notification.showLanguageSwitcher"
            }
          ],
          attrs: {
            classes: ["alpheios-notification-area__control"],
            data: _vm.settings.featureOptions.items.preferredLanguage,
            "show-title": false
          },
          on: { change: _vm.featureOptionChanged }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "alpheios-notification-area__close-btn",
            on: {
              click: function($event) {
                return _vm.$store.commit("ui/resetNotification")
              }
            }
          },
          [_c("close-icon")],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLoginNotification,
            expression: "showLoginNotification"
          }
        ],
        staticClass:
          "alpheios-notification-area__notification alpheios-notification-area__notification--important alpheios-notification-area__login-notification",
        attrs: { "data-count": _vm.$store.state.auth.notification.count }
      },
      [
        _c("div", {
          staticClass: "alpheios-notification-area__msg",
          domProps: {
            innerHTML: _vm._s(
              _vm.l10n.getMsg(_vm.$store.state.auth.notification.text)
            )
          }
        }),
        _vm._v(" "),
        _c("login", {
          staticClass: "alpheios-notification-area__control",
          attrs: { "btn-class": "alpheios-button-primary" }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "alpheios-notification-area__close-btn",
            on: {
              click: function($event) {
                return _vm.$store.commit("auth/resetNotification")
              }
            }
          },
          [_c("close-icon")],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }