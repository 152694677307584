//
//
//
//
//
//
//
//
//
//

export default {
  name: 'WordTQSourceBlock',
  props: {
    source: {
      type: String,
      required: true
    },
    tqSelectors: {
      type: Array,
      required: true
    }
  },
  computed: {
  },
  methods: {
  }
}
