//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProgressBar',
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    }
  }
}
